import { Ads, FilterOptions } from '../types/ads/Ads';

import api from '../api';

export default class AdsService {
    create = async (adsObj: any): Promise<Ads> => {
        try {
            const response = await api.post('/ads/create', adsObj, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(error.response.data.message.message);
        }
    };

    findAdDetailsById = async (adId: string): Promise<Ads> => {
        try {
            const response = await api.get<Ads>(`/ads/by-id/${adId}`);
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(error.response.data.message.message);
        }
    };

    findAdsWithOptions = async (filterOptions: FilterOptions): Promise<{ ads: Ads[]; totalRecords: number }> => {
        try {
            const response = await api.get<{ ads: Ads[]; totalRecords: number }>('/ads/find-with-options', { params: filterOptions });
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(error.response.data.message.message);
        }
    };

    getLatestAdsForEachCategory = async (): Promise<Array<{ _id: string; ads: Ads[] }>> => {
        try {
            const response = await api.get<Array<{ _id: string; ads: Ads[] }>>('/ads/latest-by-category');
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(error.response.data.message.message);
        }
    };

    findAdsByCategory = async (categoryId: number, skip = 0, limit = 10): Promise<{ ads: Ads[]; totalRecords: number }> => {
        try {
            const response = await api.get<Ads[]>(`/ads/category/${categoryId}`, {
                params: {
                    skip,
                    limit
                }
            });

            const count = await api.get(`/ads/category/${categoryId}/count`);
            return {
                ads: response.data,
                totalRecords: count.data
            };
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(error.response.data.message.message);
        }
    };
}
