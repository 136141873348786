import axios from 'axios';

const api = axios.create({
    baseURL: 'https://postlyx.herokuapp.com', // Replace with your actual backend API base URL
    timeout: 10000, // Set a timeout, for example, 10 seconds
    headers: {
        'Content-Type': 'application/json'
    }
});

// Function to set the authorization token in the axios instance
export const setAuthToken = (token) => {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
};

export default api;
