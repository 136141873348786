import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { onCheckToken, onGetProfile } from './store/features/auth/AuthAction';
import { useAppDispatch, useAppSelector } from './hooks/utils/ReduxUtil';

import AdsFeed from './components/ads/AdsFeed';
import { AllCategories } from './types/Constants/Categories';
import AppBreadcrumb from './AppBreadcrumb';
import AppConfig from './AppConfig';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppRightMenu from './AppRightMenu';
import AppTopbar from './AppTopbar';
import { Avatar } from 'primereact/avatar';
import BlocksDemo from './components/BlocksDemo';
import { Button } from 'primereact/button';
import ButtonDemo from './components/ButtonDemo';
import { CATEGORY_FEATURES } from './types/Constants/Features';
import CalendarDemo from './pages/CalendarDemo';
import ChartDemo from './components/ChartDemo';
import Chat from './components/ads/Chat';
import CrudDemo from './pages/CrudDemo';
import Dashboard from './components/Dashboard';
import { Dialog } from 'primereact/dialog';
import Documentation from './components/Documentation';
import EmptyPage from './pages/EmptyPage';
import FileDemo from './components/FileDemo';
import FloatLabelDemo from './components/FloatLabelDemo';
import FormLayoutDemo from './components/FormLayoutDemo';
import Help from './pages/Help';
import Home from './components/ads/Home';
import IconsDemo from './utilities/IconsDemo';
import InputDemo from './components/InputDemo';
import InvalidStateDemo from './components/InvalidStateDemo';
import Invoice from './pages/Invoice';
import ListDemo from './components/ListDemo';
import ListNewAds from './components/ads/ListNewAds';
import MediaDemo from './components/MediaDemo';
import MenuDemo from './components/MenuDemo';
import MessagesDemo from './components/MessagesDemo';
import MiscDemo from './components/MiscDemo';
import { NotFound } from './pages/NotFound';
import OverlayDemo from './components/OverlayDemo';
import PanelDemo from './components/PanelDemo';
import PrimeReact from 'primereact/api';
import { ROUTES } from './types/Routes';
import RenderAd from './components/ads/RenderAd';
import SearchResults from './components/ads/SearchResults';
import TableDemo from './components/TableDemo';
import TimelineDemo from './pages/TimelineDemo';
import { Tooltip } from 'primereact/tooltip';
import TreeDemo from './components/TreeDemo';
import UserDashboard from './components/ads/UserDashboard';
import { classNames } from 'primereact/utils';
import { setAuthToken } from './api';

const App = (props: any) => {
    const [initialized, setInitialized] = useState(false);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('horizontal');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
    const [visible, setVisible] = useState(false);
    const { isLoggedIn, user, token } = useAppSelector((state) => state.auth);
    const copyTooltipRef = useRef<any>();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    PrimeReact.ripple = true;

    const featuresConfig = [
        {
            feature: 'realEstate',
            isEnabled: true, // Replace with actual feature flag
            menuData: {
                label: 'Real Estate',
                icon: 'pi pi-bars',
                categoryIndex: 0
            }
        },
        {
            feature: 'vehicles',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Vehicles',
                icon: 'pi pi-bars',
                categoryIndex: 1
            }
        },
        {
            feature: 'electronics',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Electronics',
                icon: 'pi pi-bars',
                categoryIndex: 2
            }
        },
        {
            feature: 'home&garden',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Home & Garden',
                icon: 'pi pi-bars',
                categoryIndex: 3
            }
        },
        {
            feature: 'fashion&beauty',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Fashion & Beauty',
                icon: 'pi pi-bars',
                categoryIndex: 4
            }
        },
        {
            feature: 'jobs',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Jobs',
                icon: 'pi pi-bars',
                categoryIndex: 5
            }
        },
        {
            feature: 'services',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Services',
                icon: 'pi pi-bars',
                categoryIndex: 6
            }
        },
        {
            feature: 'pets',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Pets',
                icon: 'pi pi-bars',
                categoryIndex: 7
            }
        },
        {
            feature: 'hobbies&interests',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Hobbies & Interests',
                icon: 'pi pi-bars',
                categoryIndex: 8
            }
        },
        {
            feature: 'community',
            isEnabled: CATEGORY_FEATURES, // Replace with actual feature flag
            menuData: {
                label: 'Community',
                icon: 'pi pi-bars',
                categoryIndex: 9
            }
        }
    ];

    const createMenuItem = (label: any, icon: any, category: any) => {
        return {
            label,
            icon,
            items: category.sub.map((cat: any) => {
                return { label: cat, icon: 'pi pi-circle-fill', to: '/' + cat };
            })
        };
    };

    //Initialize the menu
    let menu = [];

    // Loop over the feature config and add each feature to the menu if it's enabled
    for (const featureConfig of featuresConfig) {
        if (featureConfig.isEnabled) {
            const { label, icon, categoryIndex } = featureConfig.menuData;
            const category = AllCategories[categoryIndex];
            const menuItem = createMenuItem(label, icon, category);
            menu.push(menuItem);
        }
    }
    /*const menu = [
        {
            label: 'Real Estate',
            icon: 'pi pi-bars',
            items: AllCategories[0].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Vehicles',
            icon: 'pi pi-bars',
            items: AllCategories[1].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Electronics',
            icon: 'pi pi-bars',
            items: AllCategories[2].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Home & Garden',
            icon: 'pi pi-bars',
            items: AllCategories[3].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Fashion & Beauty',
            icon: 'pi pi-bars',
            items: AllCategories[4].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Jobs',
            icon: 'pi pi-bars',
            items: AllCategories[5].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Services',
            icon: 'pi pi-bars',
            items: AllCategories[6].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Pets',
            icon: 'pi pi-bars',
            items: AllCategories[7].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Hobbies & Interests',
            icon: 'pi pi-bars',
            items: AllCategories[8].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        CATEGORY_FEATURES && {
            label: 'Community',
            icon: 'pi pi-bars',
            items: AllCategories[9].sub.map((cat) => {
                return { label: cat, icon: 'pi  pi-circle-fill', to: '/' + cat };
            })
        },
        {
            label: 'UI Kit',
            icon: 'pi pi-star',
            items: [
                { label: 'Form Layout', icon: 'pi pi-id-card', to: '/formlayout' },
                { label: 'Input', icon: 'pi pi-check-square', to: '/input' },
                { label: 'Float Label', icon: 'pi pi-bookmark', to: '/floatlabel' },
                {
                    label: 'Invalid State',
                    icon: 'pi pi-exclamation-circle',
                    to: '/invalidstate'
                },
                {
                    label: 'Button',
                    icon: 'pi pi-mobile',
                    to: '/button',
                    className: 'rotated-icon'
                },
                { label: 'Table', icon: 'pi pi-table', to: '/table' },
                { label: 'List', icon: 'pi pi-list', to: '/list' },
                { label: 'Tree', icon: 'pi pi-share-alt', to: '/tree' },
                { label: 'Panel', icon: 'pi pi-tablet', to: '/panel' },
                { label: 'Overlay', icon: 'pi pi-clone', to: '/overlay' },
                { label: 'Media', icon: 'pi pi-image', to: '/media' },
                { label: 'Menu', icon: 'pi pi-bars', to: '/menu' },
                { label: 'Message', icon: 'pi pi-comment', to: '/message' },
                { label: 'File', icon: 'pi pi-file', to: '/file' },
                { label: 'Chart', icon: 'pi pi-chart-bar', to: '/chart' },
                { label: 'Misc', icon: 'pi pi-circle', to: '/misc' }
            ]
        },
        {
            label: 'PrimeBlocks',
            icon: 'pi pi-prime',
            items: [
                {
                    label: 'Free Blocks',
                    icon: 'pi pi-eye',
                    to: '/blocks',
                    badge: 'NEW'
                },
                {
                    label: 'All Blocks',
                    icon: 'pi pi-globe',
                    url: 'https://www.primefaces.org/primeblocks-react',
                    target: '_blank'
                }
            ]
        },
        {
            label: 'Utilities',
            icon: 'pi pi-compass',
            items: [
                { label: 'Icons', icon: 'pi pi-prime', to: '/icons' },
                {
                    label: 'PrimeFlex',
                    icon: 'pi pi-desktop',
                    url: 'https://www.primefaces.org/primeflex',
                    target: '_blank'
                }
            ]
        },
        {
            label: 'Pages',
            icon: 'pi pi-briefcase',
            items: [
                { label: 'Crud', icon: 'pi pi-pencil', to: '/crud' },
                { label: 'Calendar', icon: 'pi pi-calendar-plus', to: '/calendar' },
                { label: 'Timeline', icon: 'pi pi-calendar', to: '/timeline' },
                {
                    label: 'Landing',
                    icon: 'pi pi-globe',
                    url: 'assets/pages/landing.html',
                    target: '_blank'
                },
                { label: 'Login', icon: 'pi pi-sign-in', to: '/login' },
                { label: 'Invoice', icon: 'pi pi-dollar', to: '/invoice' },
                { label: 'Help', icon: 'pi pi-question-circle', to: '/help' },
                { label: 'Error', icon: 'pi pi-times-circle', to: '/error' },
                {
                    label: 'Not Found',
                    icon: 'pi pi-exclamation-circle',
                    to: '/notfound'
                },
                { label: 'Access Denied', icon: 'pi pi-lock', to: '/access' },
                { label: 'Empty Page', icon: 'pi pi-circle', to: '/empty' }
            ]
        },

        {
            label: 'Create New Listing',
            className: 'info-color', // Add the custom class
            icon: 'pi pi-plus',
            command: () => {
                if (isLoggedIn === true || localStorage.getItem('access_token') !== null) navigate(ROUTES.LIST_NEW_ADS);
                if (user?.isVerified === false) setVisible(true);
                if (isLoggedIn === false && user === undefined && localStorage.getItem('access_token') === null) navigate(ROUTES.LOGIN);
            }
        },
        isLoggedIn === false &&
            user === undefined &&
            localStorage.getItem('access_token') === null && {
                label: 'SignIn / SignUp',
                className: 'info-color', // Add the custom class
                icon: 'pi pi-user',
                command: () => {
                    if (isLoggedIn === false && user === undefined && localStorage.getItem('access_token') === null) navigate(ROUTES.LOGIN);
                }
            }
    ];*/

    menu.push(
        {
            label: 'Create New Listing',
            className: 'info-color', // Add the custom class
            icon: 'pi pi-plus',
            command: () => {
                if (isLoggedIn === true || localStorage.getItem('access_token') !== null) navigate(ROUTES.LIST_NEW_ADS);
                if (user?.isVerified === false) setVisible(true);
                if (isLoggedIn === false && user === undefined && localStorage.getItem('access_token') === null) navigate(ROUTES.LOGIN);
            }
        },
        isLoggedIn === false &&
            user === undefined &&
            localStorage.getItem('access_token') === null && {
                label: 'SignIn / SignUp',
                className: 'info-color', // Add the custom class
                icon: 'pi pi-user',
                command: () => {
                    if (isLoggedIn === false && user === undefined && localStorage.getItem('access_token') === null) navigate(ROUTES.LOGIN);
                }
            }
    );
    const routes = [
        { parent: 'Dashboard', label: 'Sales Dashboard' },
        { parent: 'UI Kit', label: 'Form Layout' },
        { parent: 'UI Kit', label: 'Input' },
        { parent: 'UI Kit', label: 'Float Label' },
        { parent: 'UI Kit', label: 'Invalid State' },
        { parent: 'UI Kit', label: 'Button' },
        { parent: 'UI Kit', label: 'Table' },
        { parent: 'UI Kit', label: 'List' },
        { parent: 'UI Kit', label: 'Panel' },
        { parent: 'UI Kit', label: 'Tree' },
        { parent: 'UI Kit', label: 'Overlay' },
        { parent: 'UI Kit', label: 'Menu' },
        { parent: 'UI Kit', label: 'Media' },
        { parent: 'UI Kit', label: 'Message' },
        { parent: 'UI Kit', label: 'File' },
        { parent: 'UI Kit', label: 'Chart' },
        { parent: 'UI Kit', label: 'Misc' },
        { parent: 'UI Blocks', label: 'Blocks' },
        { parent: 'Utilities', label: 'Icons' },
        { parent: 'Pages', label: 'Crud' },
        { parent: 'Pages', label: 'Calendar' },
        { parent: 'Pages', label: 'Timeline' },
        { parent: 'Pages', label: 'Invoice' },
        { parent: 'Pages', label: 'Login' },
        { parent: 'Pages', label: 'Help' },
        { parent: 'Pages', label: 'Empty' },
        { parent: 'Pages', label: 'Access' },
        { parent: 'Start', label: 'Documentation' }
    ];

    let rightMenuClick: any;
    let configClick: any;
    let menuClick: any;
    let searchClick: boolean = false;
    let topbarItemClick: any;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    useEffect(() => {
        dispatch(onCheckToken());
        if (!initialized && token !== undefined) {
            setAuthToken(token);
            dispatch(onGetProfile({})).then(() => setInitialized(true));
            // dispatch(onGetProfile({}));
        }
    }, [initialized, token]);

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active: any) => {
        setRightMenuActive(active);
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event: any) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });
    const verifyEmailDialog = () => {
        return (
            <Dialog header="Verify Your Email" visible={visible} style={{ width: '50vw' }} draggable={false} onHide={() => setVisible(false)}>
                <p className="m-0">Please Verify Your Email Before Listing Any Ads. Don't recive verification Email? Send Again</p>
            </Dialog>
        );
    };

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-main">
                <AppTopbar
                    items={menu}
                    menuMode={menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={menuActive}
                    topbarMenuActive={topbarMenuActive}
                    activeInlineProfile={activeInlineProfile}
                    onTopbarItemClick={onTopbarItemClick}
                    onMenuButtonClick={onMenuButtonClick}
                    onSidebarMouseOver={onSidebarMouseOver}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onToggleMenu={onToggleMenu}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    onMenuClick={onMenuClick}
                    onMenuItemClick={onMenuItemClick}
                    onRootMenuItemClick={onRootMenuItemClick}
                    resetActiveIndex={resetActiveIndex}
                />

                <AppMenu
                    model={menu}
                    onRootMenuItemClick={onRootMenuItemClick}
                    onMenuItemClick={onMenuItemClick}
                    onToggleMenu={onToggleMenu}
                    onMenuClick={onMenuClick}
                    menuMode={menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={menuActive}
                    sidebarActive={sidebarActive}
                    sidebarStatic={sidebarStatic}
                    pinActive={pinActive}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onSidebarMouseOver={onSidebarMouseOver}
                    activeInlineProfile={activeInlineProfile}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    resetActiveIndex={resetActiveIndex}
                />

                <AppBreadcrumb routes={routes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode} onRightMenuButtonClick={() => {}} onInputClick={onInputClick} searchActive={searchActive} breadcrumbClick={breadcrumbClick} />

                <div className="layout-main-content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path={ROUTES.USER_DASHBOARD} element={<UserDashboard />} />
                        <Route path={ROUTES.LIST_NEW_ADS} element={<ListNewAds />} />
                        <Route path={ROUTES.ADS_FEED} element={<AdsFeed />} />
                        <Route path={ROUTES.ADS_DETAILS} element={<RenderAd />} />
                        <Route path={ROUTES.ADS_SEARCH} element={<SearchResults />} />
                        <Route path={ROUTES.CHAT} element={<Chat />} />
                        <Route path="*" element={<NotFound colorScheme={props.colorScheme} />} />
                        <Route path="/documentation" element={<Documentation />} />
                        <Route path="/formlayout" element={<FormLayoutDemo />} />
                        <Route path="/floatlabel" element={<FloatLabelDemo />} />
                        <Route path="/input" element={<InputDemo />} />
                        <Route path="/invalidstate" element={<InvalidStateDemo />} />
                        <Route path="/button" element={<ButtonDemo />} />
                        <Route path="/table" element={<TableDemo />} />
                        <Route path="/list" element={<ListDemo />} />
                        <Route path="/tree" element={<TreeDemo />} />
                        <Route path="/panel" element={<PanelDemo />} />
                        <Route path="/overlay" element={<OverlayDemo />} />
                        <Route path="/menu/*" element={<MenuDemo />} />
                        <Route path="/message" element={<MessagesDemo />} />
                        <Route path="/media" element={<MediaDemo />} />
                        <Route path="/file" element={<FileDemo />} />
                        <Route path="/chart" element={<ChartDemo colorMode={props.colorScheme} location={location} />} />
                        <Route path="/misc" element={<MiscDemo />} />
                        <Route path="/blocks" element={<BlocksDemo />} />
                        <Route path="/icons" element={<IconsDemo />} />
                        <Route path="/crud" element={<CrudDemo />} />
                        <Route path="/calendar" element={<CalendarDemo />} />
                        <Route path="/help" element={<Help />} />
                        <Route path="/invoice" element={<Invoice colorMode={props.colorScheme} location={location} />} />
                        <Route path="/empty" element={<EmptyPage />} />
                        <Route path="/timeline" element={<TimelineDemo />} />
                    </Routes>
                </div>

                <AppFooter colorScheme={props.colorScheme} />
            </div>

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />
        </div>
    );
};

export default App;
