import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/utils/ReduxUtil';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
import { Galleria } from 'primereact/galleria';
import { Panel } from 'primereact/panel';
import PhotoService from '../../service/PhotoService';
import { Prefix } from '../../types/Constants/LocalStorage';
import { ProgressSpinner } from 'primereact';
import { onCheckToken } from '../../store/features/auth/AuthAction';
import { onFindAdDetailsById } from '../../store/features/ads/AdsAction';
import { onGetAll } from '../../store/features/category/CategoryAction';
import { onGetFieldBySubCategory } from '../../store/features/field/FieldsAction';
import useAuth from '../../hooks/useAuth';

const RenderAd = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { fields, fieldsError, fieldsLoading } = useAppSelector((state) => state.fields);
    const { adById, adByIdError, adByIdLoading } = useAppSelector((state) => state.ads);
    const [galleriaImages, setGalleriaImages] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [subCategoryId, setSubCategoryId] = useState(null);
    const [adId, setAdId] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        dispatch(onCheckToken());
        dispatch(onGetAll({}));

        setSubCategoryId(queryParams.get('sub-category-id'));
        setAdId(queryParams.get('ad-id'));
    }, [location, dispatch]);

    useEffect(() => {
        if (subCategoryId) {
            dispatch(onGetFieldBySubCategory(subCategoryId));
        }
    }, [subCategoryId, dispatch]);

    useEffect(() => {
        if (adId) {
            dispatch(onFindAdDetailsById(adId));
        }
    }, [adId, dispatch]);

    useEffect(() => {
        if (adById) {
            const photoService = new PhotoService();
            photoService.getAdsImagesFromUrls(adById.images, 80, 60).then((images) => setGalleriaImages(images));
        }
    }, [adById, dispatch]);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    ///// so so fucking ugly
    const mapCriteria = () => {
        return fields
            .filter((field) => !['title', 'description', 'price', 'address', 'city'].includes(field.name))
            .sort((a, b) => {
                return a.options.length === 0 ? -1 : 1;
            })
            .map((field, index) => {
                return !Array.isArray(adById.fields[field.name]) ? (
                    <div key={index} className="col-12 md:col-6 lg:col-3">
                        <div className="surface-card shadow-2 p-3 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3">{field.label}</span>
                                    <div className="text-900 font-medium text-xl">{adById.fields[field.name] || ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div key={index} className="col-12 md:col-6 lg:col-3">
                        <div className="surface-card shadow-2 p-3 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3">{field.label}</span>

                                    <div className="grid">
                                        {adById.fields[field.name].map((opt, optIndex) => (
                                            <Chip key={optIndex} label={opt} className="mr-2 mb-2" />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    };

    const formatUserInput = (input) => {
        // Normalize multiple consecutive newline characters
        let normalizedInput = input.replace(/\n{3,}/g, '\n\n');

        // Normalize multiple consecutive spaces
        normalizedInput = normalizedInput.replace(/ +/g, ' ');

        // Remove leading and trailing whitespace
        normalizedInput = normalizedInput.trim();

        return normalizedInput.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    const getInitials = (firstName, lastName) => {
        return (firstName.slice(0, 1) + lastName.slice(0, 1)).toUpperCase();
    };

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const itemTemplate = (item) => {
        return <img src={item.previewImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    };

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    };

    return (
        <div className="flex-1 border-1 surface-border surface-card border-round">
            <div className="flex flex-column h-full">
                <div className="flex align-items-center border-bottom-1 surface-border p-3 lg:p-6">
                    <div className="relative flex align-items-center mr-3">
                        <img src="https://www.primefaces.org/apollo-react/demo/images/avatar/ionibowcher.png" alt="Ioni Bowcher" className="w-4rem h-4rem border-circle shadow-4" />
                        <span className="w-1rem h-1rem border-circle border-2 surface-border absolute bottom-0 right-0 bg-green-400"></span>
                    </div>
                    <div className="mr-2">
                        <span className="text-900 font-semibold block">Ioni Bowcher</span>
                        <span className="text-700">Last active 1 hour ago</span>
                    </div>
                    <div className="flex align-items-center ml-auto">
                        <button type="button" className="p-button p-component p-button-rounded p-button-outlined p-button-secondary mr-3 p-button-icon-only">
                            <span className="p-button-icon p-c pi pi-phone"></span>
                            <span className="p-button-label p-c">&nbsp;</span>
                        </button>
                        <button type="button" className="p-button p-component p-button-rounded p-button-outlined p-button-secondary p-button-icon-only">
                            <span className="p-button-icon p-c pi pi-ellipsis-v"></span>
                            <span className="p-button-label p-c">&nbsp;</span>
                        </button>
                    </div>
                </div>
                <div className="p-3 md:px-4 lg:px-6 lg:py-4 mt-2 overflow-y-auto" style={{ maxHeight: '53vh' }}>
                    <div>
                        <div className="grid grid-nogutter mb-4">
                            <div className="mr-3 mt-1">
                                <img src="https://www.primefaces.org/apollo-react/demo/images/avatar/ionibowcher.png" alt="Ioni Bowcher" className="w-3rem h-3rem border-circle shadow-4" />
                            </div>
                            <div className="col mt-3">
                                <p className="text-900 font-semibold mb-3">Ioni Bowcher</p>
                                <span className="text-700 inline-block font-medium border-1 surface-border p-3 white-space-normal border-round" style={{ wordBreak: 'break-word', maxWidth: '80%' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                </span>
                                <p className="text-700 mt-3">
                                    21:25<i className="pi pi-check ml-2 text-green-400"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-nogutter mb-4">
                            <div className="mr-3 mt-1">
                                <img src="https://www.primefaces.org/apollo-react/demo/images/avatar/ionibowcher.png" alt="Ioni Bowcher" className="w-3rem h-3rem border-circle shadow-4" />
                            </div>
                            <div className="col mt-3">
                                <p className="text-900 font-semibold mb-3">Ioni Bowcher</p>
                                <span className="text-700 inline-block font-medium border-1 surface-border p-3 white-space-normal border-round" style={{ wordBreak: 'break-word', maxWidth: '80%' }}>
                                    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                </span>
                                <p className="text-700 mt-3">
                                    21:26<i className="pi pi-check ml-2 text-green-400"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-nogutter mb-4">
                            <div className="col mt-3 text-right">
                                <span className="inline-block text-left font-medium border-1 surface-border bg-primary-100 text-primary-900 p-3 white-space-normal border-round" style={{ wordBreak: 'break-word', maxWidth: '80%' }}>
                                    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                </span>
                                <p className="text-700 mt-3">
                                    21:26 <i className="pi pi-check ml-2 text-green-400"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-3 md:p-4 lg:p-6 flex flex-column sm:flex-row align-items-center mt-auto border-top-1 surface-border gap-3">
                    <input id="message" type="text" placeholder="Type a message" className="p-inputtext p-component flex-1 w-full sm:w-auto border-round" value="" />
                    <div className="flex w-full sm:w-auto gap-3">
                        <button className="p-button p-component p-button p-button-secondary w-full sm:w-auto justify-content-center text-xl">😀</button>
                        <button aria-label="Send" className="p-button p-component w-full sm:w-auto">
                            <span className="p-button-icon p-c p-button-icon-left pi pi-send"></span>
                            <span className="p-button-label p-c">Send</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RenderAd;
