import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { onAdsCreate, onFindAdDetailsById, onFindAdsByCategory, onFindAdsWithOptions, onFindLatestByCategory } from './AdsAction';

import { Ads } from '../../../types/ads/Ads';

const name = 'ads';

type State = {
    // create state
    createdAd: Ads | undefined;
    createdAdLoading: boolean;
    createdAdError: string | undefined;

    // get by id state
    adById: Ads | undefined;
    adByIdLoading: boolean;
    adByIdError: string | undefined;

    // find With Options state

    adsWithOptions: { ads: Ads[]; totalRecords: number };
    adsWithOptionsLoading: boolean;
    adsWithOptionsError: string | undefined;

    // find With Options state

    latestAds: Array<{ _id: string; ads: Ads[] }>;
    latestAdsLoading: boolean;
    latestAdsError: string | undefined;

    // find by category state

    adsByCategory: { ads: Ads[]; totalRecords: number };
    adsByCategoryLoading: boolean;
    adsByCategoryError: string | undefined;
};

const initialState: State = {
    createdAd: undefined,
    createdAdLoading: false,
    createdAdError: undefined,
    adById: undefined,
    adByIdLoading: false,
    adByIdError: undefined,
    adsWithOptions: { ads: [], totalRecords: 0 },
    adsWithOptionsLoading: false,
    adsWithOptionsError: undefined,
    latestAds: [],
    latestAdsLoading: false,
    latestAdsError: undefined,
    adsByCategory: { ads: [], totalRecords: 0 },
    adsByCategoryLoading: false,
    adsByCategoryError: undefined
};

export const adsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /// onCreate
        builder.addCase(onAdsCreate.pending, (state) => ({
            ...state,
            createdAdLoading: true
        }));
        builder.addCase(onAdsCreate.fulfilled, (state, action: PayloadAction<Ads>) => ({
            ...state,
            createdAd: action.payload,
            createdAdLoading: false
        }));
        builder.addCase(onAdsCreate.rejected, (state, { payload }) => ({
            ...state,
            createdAdLoading: false,
            //@ts-ignore
            createdAdError: payload.message
        }));

        /// onFindById
        builder.addCase(onFindAdDetailsById.pending, (state) => ({
            ...state,
            adByIdLoading: true
        }));
        builder.addCase(onFindAdDetailsById.fulfilled, (state, action: PayloadAction<Ads>) => ({
            ...state,
            adById: action.payload,
            adByIdLoading: false
        }));
        builder.addCase(onFindAdDetailsById.rejected, (state, { payload }) => ({
            ...state,
            adByIdLoading: false,
            //@ts-ignore
            adByIdError: payload.message
        }));

        /// onfindWithOptions
        builder.addCase(onFindAdsWithOptions.pending, (state) => ({
            ...state,
            adsWithOptionsLoading: true
        }));
        builder.addCase(onFindAdsWithOptions.fulfilled, (state, action: PayloadAction<{ ads: Ads[]; totalRecords: number }>) => ({
            ...state,
            adsWithOptions: action.payload,
            adsWithOptionsLoading: false
        }));
        builder.addCase(onFindAdsWithOptions.rejected, (state, { payload }) => ({
            ...state,
            adsWithOptionsLoading: false,
            //@ts-ignore
            adsWithOptionsError: payload.message
        }));

        /// onGetLatestByCategory
        builder.addCase(onFindLatestByCategory.pending, (state) => ({
            ...state,
            latestAdsLoading: true
        }));
        builder.addCase(onFindLatestByCategory.fulfilled, (state, action: PayloadAction<Array<{ _id: string; ads: Ads[] }>>) => ({
            ...state,
            latestAds: action.payload,
            latestAdsLoading: false
        }));
        builder.addCase(onFindLatestByCategory.rejected, (state, { payload }) => ({
            ...state,
            latestAdsLoading: false,
            //@ts-ignore
            latestAdsError: payload.message
        }));

        /// onFindByCategory
        builder.addCase(onFindAdsByCategory.pending, (state) => ({
            ...state,
            adsByCategoryLoading: true
        }));
        builder.addCase(onFindAdsByCategory.fulfilled, (state, action: PayloadAction<{ ads: Ads[]; totalRecords: number }>) => ({
            ...state,
            adsByCategory: action.payload,
            adsByCategoryLoading: false
        }));
        builder.addCase(onFindAdsByCategory.rejected, (state, { payload }) => ({
            ...state,
            adsByCategoryLoading: false,
            //@ts-ignore
            adsByCategoryError: payload.message
        }));
    }
});

export default adsSlice.reducer;
