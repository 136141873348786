import { LoginResponse } from '../types/response/Response';
import api from '../api';

export default class AuthService {
    register = async ({ firstName, lastName, email, password }: { firstName: string; lastName: string; email: string; password: string }): Promise<any> => {
        try {
            const response = await api.post<LoginResponse>('/auth/register', { firstName, lastName, email, password });
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(error.response.data.message.message);
        }
    };

    login = async ({ email, password }: { email: string; password: string }): Promise<LoginResponse> => {
        try {
            const response = await api.post<LoginResponse>('/auth/login', { email, password });

            if (response.data.access_token && response.data.user) {
                localStorage.setItem('access_token', response.data.access_token);
            }

            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }

            throw new Error(error.response.data.message.message);
        }
    };

    getProfile = async (): Promise<LoginResponse> => {
        try {
            const response = await api.get('/auth/me');
            return response.data;
        } catch (error: any) {
            console.log(error.response);
            if (!error.response) {
                throw new Error('Network Error');
            }

            throw new Error(error.response.data.message);
        }
    };

    resendVerificationEmail = async (email: string): Promise<LoginResponse> => {
        try {
            const response = await api.post<LoginResponse>('/auth/resend-verification-email', { email });
            return response.data;
        } catch (error: any) {
            console.log(error.response);
            if (!error.response) {
                throw new Error('Network Error');
            }

            throw new Error(error.response.data.message.message);
        }
    };

    forgotPassword = async (email: any) => {
        console.log('forgotPassword', email);
        try {
            await api.post('/auth/forgot-password', { email });
        } catch (error: any) {
            console.log(error.response);
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(error.response.data.message.message);
        }
    };

    resetPassword = async ({ token, newPassword }: { token: string; newPassword: string }) => {
        try {
            await api.post<LoginResponse>('/auth/forgot-password', { token, newPassword });
        } catch (error: any) {
            return {
                error: error.response.data
            };
        }
    };
}
