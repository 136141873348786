export const AllCategories = [
    { name: 'Real-Estate', sub: ['Houses / Apartments for Sale', 'Houses / Apartments for Rent', 'Rooms for Rent', 'Office / Commercial Space', 'Land for Sale', 'Vacation Rentals', 'Real Estate Services', 'Other Real Estate'] },
    { name: 'Vehicles', sub: ['Cars', 'Motorcycles', 'Trucks / Commercial Vehicles', 'Auto Parts and Accessories', 'Boats', 'RVs / Campers / Caravans', 'Vehicle Services'] },
    { name: 'Electronics', sub: ['Mobile Phones / Tablets', 'Computers / Laptops', 'TV / Audio / Video', 'Cameras / Camcorders', 'Video Games / Consoles', 'Electronic Accessories'] },
    { name: 'Home & Garden', sub: ['Furniture', 'Appliances', 'Home Decor', 'Tools / Equipment', 'Garden / Outdoor Items', 'Household Services'] },
    { name: 'Fashion & Beauty', sub: ['Clothing / Accessories', 'Watches / Jewelry', 'Health / Beauty Products', 'Fashion Services'] },
    { name: 'Jobs', sub: ['Full-time Jobs', 'Part-time Jobs', 'Freelance / Remote Jobs', 'Internships', 'Job Seekers'] },
    { name: 'Services', sub: ['Home Services', 'Business Services', 'Events / Catering Services', 'Travel / Tourism Services', 'Education / Training Services', 'Health / Medical Services', 'Legal / Financial Services'] },
    { name: 'Pets', sub: ['Dogs / Cats', 'Birds', 'Fish', 'Other Pets', 'Pet Accessories', 'Pet Services'] },
    { name: 'Hobbies & Interests', sub: ['Sports / Fitness Equipment', 'Musical Instruments', 'Books / Magazines', 'Collectibles / Antiques', 'Art / Crafts', 'Tickets / Events'] },
    { name: 'Community', sub: ['Announcements / Events', 'Carpool / Rideshare', 'Classes / Workshops', 'Volunteers', 'Lost and Found'] }
];

export const enum Categories {
    REAL_ESTATE = 'Real-Estate',
    VEHICLES = 'Vehicles',
    ELECTRONICS = 'Electronics',
    HOME_GARDEN = 'Home & Garden',
    FASHION_BEAUTY = 'Fashion & Beauty',
    JOBS = 'Jobs',
    SERVICES = 'Services',
    PETS = 'Pets',
    HOBBIES_INTERESTS = 'Hobbies & Interests',
    COMMUNITY = 'Community'
}

export const enum RealEstateSubCategories {
    HOUSES_APARTMENTS_FOR_SALE = 'Houses / Apartments for Sale',
    HOUSES_APARTMENTS_FOR_RENT = 'Houses / Apartments for Rent',
    ROOMS_FOR_RENT = 'Rooms for Rent',
    OFFICE_COMMERCIAL_SPACE = 'Office / Commercial Space',
    LAND_FOR_SALE = 'Land for Sale',
    VACATION_RENTALS = 'Vacation Rentals',
    REAL_ESTATE_SERVICES = 'Real Estate Services',
    OTHER_REAL_ESTATE = 'Other Real Estate'
}

export const enum ElectronicsSubCategories {
    MOBILE_PHONES_TABLETS = 'Mobile Phones / Tablets',
    COMPUTERS_LAPTOPS = 'Computers / Laptops',
    TV_AUDIO_VIDEO = 'TV / Audio / Video',
    CAMERAS_CAMCORDERS = 'Cameras / Camcorders',
    VIDEO_GAMES_CONSOLES = 'Video Games / Consoles',
    ELECTRONIC_ACCESSORIES = 'Electronic Accessories'
}

export type AllSubCategories = RealEstateSubCategories | ElectronicsSubCategories;
