import AdsService from '../../../service/AdsService';
import { FilterOptions } from '../../../types/ads/Ads';
import { createAsyncThunk } from '@reduxjs/toolkit';

const adsService = new AdsService();

export const onAdsCreate = createAsyncThunk('ads/create', async (adsObj: any, thunkAPI) => {
    try {
        return await adsService.create(adsObj);
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onFindAdDetailsById = createAsyncThunk('ads/by-id', async (adId: string, thunkAPI) => {
    try {
        return await adsService.findAdDetailsById(adId);
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onFindAdsWithOptions = createAsyncThunk('ads/find-with-options', async (filterOptions: FilterOptions, thunkAPI) => {
    try {
        console.log(filterOptions);
        return await adsService.findAdsWithOptions(filterOptions);
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onFindLatestByCategory = createAsyncThunk('ads/find-latest-by-category', async (tmp: any, thunkAPI) => {
    try {
        return await adsService.getLatestAdsForEachCategory();
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onFindAdsByCategory = createAsyncThunk('ads/find-by-category', async ({ categoryId, skip, limit }: { categoryId: number; skip: number; limit: number }, thunkAPI) => {
    try {
        return await adsService.findAdsByCategory(categoryId, skip, limit);
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});
