export const cities = [
    { name: 'Gafsa' },
    { name: 'Jendouba' },
    { name: 'Kairouan' },
    { name: 'Kasserine' },
    { name: 'Ariana' },
    { name: 'Beja' },
    { name: 'Ben Arous' },
    { name: 'Bizerte' },
    { name: 'Gabes' },
    { name: 'Zaghouan' },
    { name: 'Tunis' },
    { name: 'Tozeur' },
    { name: 'Tataouine' },
    { name: 'Sousse' },
    { name: 'Siliana' },
    { name: 'Sidi Bouzid' },
    { name: 'Sfax' },
    { name: 'Nabeul' },
    { name: 'Monastir' },
    { name: 'Medenine' },
    { name: 'Manouba' },
    { name: 'Mahdia' },
    { name: 'Kef' },
    { name: 'Kebili' }
];
