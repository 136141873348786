import FieldService from '../../../service/FieldService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fieldService = new FieldService();

export const onGetFieldBySubCategory = createAsyncThunk('fields/bySubCategory', async (subCategoryId: string, thunkAPI) => {
    try {
        return await fieldService.getFieldBySubCategory(subCategoryId);
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});
