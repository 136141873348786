import { Ads } from '../../../types/ads/Ads';

const AdsCard = (ads: Ads) => {
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="product-grid-item card">
                    <div className="product-grid-item-content">
                        <img src={ads.images[0]} />
                        <div className="product-description">
                            <b>{ads.title.slice(0, 27) + ' ...'}</b>
                        </div>
                    </div>

                    <div className="product-grid-item-bottom">
                        <span className="text-green-500 font-medium text-xl">{ads.fields.price} TND</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AdsCard;
