import { Categories, ElectronicsSubCategories, RealEstateSubCategories } from '../types/Constants/Categories';

import { FilterOptions } from '../types/ads/Ads';
import electronics_template from '../assets/Categories/electronics_template.json';
import real_estate_template from '../assets/Categories/real_estate_template.json';

export const getCategoryTemplate = (category: string, subCategory: string) => {
    switch (category as Categories) {
        case Categories.REAL_ESTATE:
            return real_estate_template.realEstate[subCategory as RealEstateSubCategories];

        case Categories.VEHICLES:
            return [];

        case Categories.ELECTRONICS:
            return electronics_template.Electronics[subCategory as ElectronicsSubCategories];

        case Categories.HOME_GARDEN:
            return [];

        case Categories.FASHION_BEAUTY:
            return [];

        case Categories.JOBS:
            return [];

        case Categories.SERVICES:
            return [];

        case Categories.PETS:
            return [];

        case Categories.HOBBIES_INTERESTS:
            return [];

        case Categories.COMMUNITY:
            return [];

        default:
            return null;
    }
};

export const buildFilter = (criteria: any) => {
    const filter: any = {};
    const projection: any = {};

    if (criteria.query) {
        filter.$text = { $search: criteria.query, $caseSensitive: false };
        projection.score = { $meta: 'textScore' };
    }

    if (criteria.categoryId) {
        filter.categoryId = criteria.categoryId;
    }

    if (criteria.subcategoryId) {
        filter.subcategoryId = criteria.subcategoryId;
    }

    if (criteria.city) {
        filter.location = criteria.city;
    }

    if (criteria.minPrice && criteria.maxPrice) {
        filter['customFields.price'] = {
            $gte: criteria.minPrice,
            $lte: criteria.maxPrice
        };
    }

    // Add more filter criteria as needed
    return { filter, projection };
};

export const buildSort = (sortCriteria: any) => {
    const sort: any = {};

    if (sortCriteria.sortBy && sortCriteria.order) {
        sort[sortCriteria.sortBy] = sortCriteria.order === 'asc' ? 1 : -1;
    }

    return sort;
};

export const createNewObjectWithKeysAtRoot = (obj: any, keysToKeepAtRoot: any) => {
    const customFields = Object.keys(obj)
        .filter((key) => !keysToKeepAtRoot.includes(key))
        .reduce((acc: any, key: any) => {
            acc[key] = obj[key] === undefined ? null : obj[key];
            return acc;
        }, {});

    const rootProps = keysToKeepAtRoot.reduce((acc: any, key: any) => {
        acc[key] = obj[key];
        return acc;
    }, {});

    return {
        ...rootProps,
        customFields
    };
};

export const latestAdsOptions = (categoryId: string): FilterOptions => {
    const userCriteria = {
        categoryId: categoryId
    };

    const userSortCriteria = {
        sortBy: 'createdAt',
        order: 'desc' // or 'asc' for ascending
    };

    const { filter, projection } = buildFilter(userCriteria);
    const sort = buildSort(userSortCriteria);

    return {
        filter,
        projection,
        sort,
        skip: 0,
        limit: 10
    };
};

export const searchOptions = (userCriteria: any, skip: number, limit: number): FilterOptions => {
    const userSortCriteria = {
        sortBy: 'createdAt',
        order: 'desc' // or 'asc' for ascending
    };

    const { filter, projection } = buildFilter(userCriteria);
    const sort = buildSort(userSortCriteria);

    return {
        filter,
        projection,
        sort,
        skip: skip,
        limit: limit
    };
};

/*const userCriteria = {
    categoryId: '60e1f7d0278d5f3a30d379e3',
    minPrice: 1000,
    maxPrice: 5000
};

const userSortCriteria = {
    sortBy: 'createdAt',
    order: 'desc' // or 'asc' for ascending
};

const filter = buildFilter(userCriteria);
const sort = buildSort(userSortCriteria);
const skip = 0;
const limit = 10;

const ads = await this.adService.findWithOptions(filter, skip, limit, sort);*/
