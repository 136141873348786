import { useAppDispatch, useAppSelector } from './utils/ReduxUtil';

import { onCheckToken } from '../store/features/auth/AuthAction';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();
    // const isAuthenticated = localStorage.getItem('access_token') !== null;

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(onCheckToken());
    }, []);

    useEffect(() => {
        if (isLoggedIn === false) {
            navigate('/ads-login');
        }
    }, [isLoggedIn, navigate]);

    return isLoggedIn;
};

export default useAuth;
