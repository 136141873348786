import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import { onLogin, onRegister, onResetRegisterStates } from '../../store/features/auth/AuthAction';
import { useAppDispatch, useAppSelector } from '../../hooks/utils/ReduxUtil';

import AuthService from '../../service/AuthService';
import BlockViewer from '../../BlockViewer';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import CountryService from '../../service/CountryService';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ROUTES } from '../../types/Routes';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';

export const SignUpForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showMessage, setShowMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const { user, registerLoading, registerError } = useAppSelector((state) => state.auth);

    const toast = useRef(null);

    const defaultValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        country: null,
        accept: false
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        reset
    } = useForm({ defaultValues });

    const onSubmit = (data) => {
        dispatch(
            onRegister({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password
            })
        );
        reset();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const showSuccess = () => {
        user !== undefined && toast.current.show({ severity: 'success', summary: 'Success', detail: 'Succesfully Registred. Please Confirm Your Email', life: 3000 });
    };

    const showError = () => {
        registerError !== undefined && toast.current.show({ severity: 'error', summary: 'Error', detail: registerError, life: 5000 });
    };

    useEffect(() => {
        if (user) {
            showSuccess();
        }

        if (registerError) {
            showError();
        }

        return () => {
            dispatch(onResetRegisterStates());
        };
    }, [user, registerError]);

    return (
        <div className="surface-card p-4 shadow-2 border-round w-full">
            <Toast ref={toast} />
            <div className="text-center mb-5">
                <img src="assets/demo/images/blocks/logos/hyper.png" alt="hyper" height="50" className="mb-3" />
                <div className="text-900 text-3xl font-medium mb-3">Sign Up</div>
                <span className="text-600 font-medium line-height-3">it's easy and quick?</span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: 'First Name is required.' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.firstName })}></label>
                            <span className="p-float-label">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}> First Name*</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />

                <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: 'Last Name is required.' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.lastName })}></label>
                            <span className="p-float-label">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}> Last Name*</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />

                <Controller
                    name="email"
                    control={control}
                    rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' } }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.email })}></label>
                            <span className="p-float-label">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}> Email*</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />

                <Controller
                    name="password"
                    control={control}
                    rules={{ required: 'Password is required.' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.password })}></label>
                            <span className="p-float-label">
                                <Password id={field.name} value={field.value} toggleMask className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}> Password*</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />

                <Controller
                    name="repeatPassword"
                    control={control}
                    rules={{
                        required: 'Your passwords do no match.',
                        validate: (val) => {
                            if (watch('password') !== val) {
                                return 'Your passwords do no match';
                            }
                        }
                    }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.repeatPassword })}></label>
                            <span className="p-float-label">
                                <Password id={field.name} value={field.value} toggleMask className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}> Repeat Password*</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />

                <div className="field-checkbox">
                    <Controller
                        name="accept"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => (
                            <>
                                <span className="p-float-label">
                                    <Checkbox id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.checked)} checked={field.value} />
                                </span>
                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.accept })}>
                                    I Agree to the terms and conditions
                                </label>
                                {getFormErrorMessage(field.name)}
                            </>
                        )}
                    />
                </div>

                <Button type="submit" label="Sign Up" icon="pi pi-user" className="w-full" loading={registerLoading} />
            </form>
        </div>
    );
};

export default SignUpForm;
