import CategoryService from '../../../service/CategoryService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const categoryService = new CategoryService();

export const onGetAll = createAsyncThunk('category/all', async (tmp: any, thunkAPI) => {
    try {
        return await categoryService.getAll();
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});
