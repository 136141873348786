import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/utils/ReduxUtil';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { CHAT_FEATURES } from '../../types/Constants/Features';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';
import { Divider } from 'primereact/divider';
import { Galleria } from 'primereact/galleria';
import { Panel } from 'primereact/panel';
import PhotoService from '../../service/PhotoService';
import { Prefix } from '../../types/Constants/LocalStorage';
import { ProgressSpinner } from 'primereact';
import { onCheckToken } from '../../store/features/auth/AuthAction';
import { onFindAdDetailsById } from '../../store/features/ads/AdsAction';
import { onGetAll } from '../../store/features/category/CategoryAction';
import { onGetFieldBySubCategory } from '../../store/features/field/FieldsAction';
import useAuth from '../../hooks/useAuth';

const RenderAd = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { fields, fieldsError, fieldsLoading } = useAppSelector((state) => state.fields);
    const { adById, adByIdError, adByIdLoading } = useAppSelector((state) => state.ads);
    const [galleriaImages, setGalleriaImages] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [subCategoryId, setSubCategoryId] = useState(null);
    const [adId, setAdId] = useState(null);

    useEffect(() => {
         const queryParams = new URLSearchParams(location.search);
        dispatch(onCheckToken());
        dispatch(onGetAll({}));
       
        setSubCategoryId(queryParams.get('sub-category-id'));
        setAdId(queryParams.get('ad-id'));
    }, [location, dispatch]);

    useEffect(() => {
        if (subCategoryId) {
            dispatch(onGetFieldBySubCategory(subCategoryId));
        }
    }, [subCategoryId, dispatch]);

    useEffect(() => {
        if (adId) {
            dispatch(onFindAdDetailsById(adId));
        }
    }, [adId, dispatch]);

    useEffect(() => {
        if (adById) {
            const photoService = new PhotoService();
            photoService.getAdsImagesFromUrls(adById.images, 80, 60).then((images) => setGalleriaImages(images));
        }
    }, [adById, dispatch]);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    ///// so so fucking ugly
    const mapCriteria = () => {
        return fields
            .filter((field) => !['title', 'description', 'price', 'address', 'city'].includes(field.name))
            .sort((a, b) => {
                return a.options.length === 0 ? -1 : 1;
            })
            .map((field, index) => {
                return !Array.isArray(adById.fields[field.name]) ? (
                    <div key={index} className="col-12 md:col-6 lg:col-3">
                        <div className="surface-card shadow-2 p-3 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3">{field.label}</span>
                                    <div className="text-900 font-medium text-xl">{adById.fields[field.name] || ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div key={index} className="col-12 md:col-6 lg:col-3">
                        <div className="surface-card shadow-2 p-3 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3">{field.label}</span>

                                    <div className="grid">
                                        {adById.fields[field.name].map((opt, optIndex) => (
                                            <Chip key={optIndex} label={opt} className="mr-2 mb-2" />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    };

    const formatUserInput = (input) => {
        // Normalize multiple consecutive newline characters
        let normalizedInput = input.replace(/\n{3,}/g, '\n\n');

        // Normalize multiple consecutive spaces
        normalizedInput = normalizedInput.replace(/ +/g, ' ');

        // Remove leading and trailing whitespace
        normalizedInput = normalizedInput.trim();

        return normalizedInput.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    const getInitials = (firstName, lastName) => {
        return (firstName.slice(0, 1) + lastName.slice(0, 1)).toUpperCase();
    };

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const itemTemplate = (item) => {
        return <img src={item.previewImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    };

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    };

    if (adById) {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="p-fluid">
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <div className="card">
                                    <Galleria
                                        value={galleriaImages}
                                        responsiveOptions={responsiveOptions}
                                        numVisible={5}
                                        circular
                                        style={{ maxWidth: '640px' }}
                                        showItemNavigators
                                        showItemNavigatorsOnHover
                                        item={itemTemplate}
                                        thumbnail={thumbnailTemplate}
                                    />
                                </div>

                                <Card title={<h2>{formatUserInput(adById.title)}</h2>} subTitle={<h5>6 Pièces · 146 m² · Grand-Aigueblanche 73260</h5>}>
                                    <div className="text-green-500 font-medium text-xl">{adById.fields['price']} TND</div>
                                    <Divider />

                                    <Card title={<h4>Description</h4>} subTitle="01/10/2023 10:39 PM" style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
                                        <p>{expanded ? formatUserInput(adById.description) : formatUserInput(adById.description.slice(0, 500) + '...')}</p>
                                        <Button label={expanded ? 'Read Less' : 'Read More'} className="p-button-text" onClick={toggleExpanded} />
                                    </Card>
                                    <Divider />

                                    <Card title={<h4>Critiria</h4>} style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
                                        <div className="grid">{mapCriteria()}</div>
                                    </Card>
                                </Card>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="card">
                                    <h5>Contact</h5>
                                    <Panel
                                        header={
                                            <div className="grid   flex align-items-center justify-content-center">
                                                <div className="col">
                                                    <Avatar label={getInitials(adById.contactDetails.firstName, adById.contactDetails.lastName)} className="mr-2" size="xlarge" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />
                                                </div>
                                                <div>
                                                    <h4>
                                                        {adById.contactDetails.firstName} {adById.contactDetails.lastName}
                                                    </h4>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <div className="grid flex align-items-center justify-content-center">
                                            <div className="col-12 md:col-6">
                                                {CHAT_FEATURES && <Button
                                                    label="Send Message"
                                                    className="field-checkbox"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                ></Button>}

                                                <Button
                                                    label="Show Phone Number"
                                                    className="field-checkbox"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                ></Button>
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="grid">
            <div className="flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    );
};

export default RenderAd;
