import React, { useEffect } from 'react';

import { onCheckToken } from '../../store/features/auth/AuthAction';
import { useAppDispatch } from '../../hooks/utils/ReduxUtil';
import useAuth from '../../hooks/useAuth';

const UserDashboard = () => {
    useAuth();
    /*const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(onCheckToken());
    }, []);*/
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>User Dashboard</h5>
                    <p>This is your empty page template to start building beautiful applications.</p>
                </div>
            </div>
        </div>
    );
};

export default UserDashboard;
