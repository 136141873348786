import { useAppDispatch, useAppSelector } from '../../../hooks/utils/ReduxUtil';
import { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Category } from '../../../types/Categories/Category';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ROUTES } from '../../../types/Routes';
import { cities } from '../../../types/Constants/Cities';
import { onFindAdsWithOptions } from '../../../store/features/ads/AdsAction';
import { searchOptions } from '../../../utilities/Utils';
import { text } from 'stream/consumers';
import { useNavigate } from 'react-router-dom';

interface SearchCardProps {
    categories: Category[];
    cities: any;
    skip?: number;
    limit?: number;
}
const SearchCard: React.FC<SearchCardProps> = ({ categories, cities, skip = 0, limit = 0 }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [city, setCity] = useState<any>(null);
    const [category, setCategory] = useState<any>(null);
    const [keywords, setKeywords] = useState('');
    const [loading, setLoading] = useState(false);
    const { adsWithOptions, adsWithOptionsError, adsWithOptionsLoading } = useAppSelector((state) => state.ads);

    const onKeywordsChange = (e: any) => {
        setKeywords(e.target.value);
    };

    const options = searchOptions(
        {
            city: city !== null ? city.name : null,
            categoryId: category !== null ? category._id : null,
            query: keywords !== '' ? keywords : null
        },
        skip,
        limit
    );

    useEffect(() => {
        dispatch(onFindAdsWithOptions(options));
    }, [skip, limit, dispatch]);

    const onSearchClick = () => {
        dispatch(onFindAdsWithOptions(options)).then(() => navigate(ROUTES.ADS_SEARCH));
    };

    return (
        <div className="grid help-page">
            <div className="col-12">
                <div className="card help-search grid">
                    <div className="help-search-content col-12">
                        <h4>Des millions de petites annonces et autant d'occasions de se faire plaisir</h4>
                        <div className="flex flex-column md:flex-row gap-2">
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-tag"></i>
                                </span>
                                <Dropdown value={category} onChange={(e) => setCategory(e.value)} options={categories} optionLabel="name" placeholder="Categories" />
                            </div>
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                    <i className="pi  pi-search"></i>
                                </span>
                                <InputText value={keywords} placeholder="What Are You Looking For ?" onChange={onKeywordsChange} />
                            </div>

                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-map-marker"></i>
                                </span>

                                <Dropdown value={city} onChange={(e) => setCity(e.value)} options={cities} optionLabel="name" placeholder="AllCities" />
                            </div>
                            <div>
                                <Button label="Search " icon="pi pi-search" loading={adsWithOptionsLoading} onClick={onSearchClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SearchCard;
