import AuthService from '../../../service/AuthService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const authService = new AuthService();

export const onLogin = createAsyncThunk('auth/login', async ({ email, password }: { email: string; password: string }, thunkAPI) => {
    try {
        return await authService.login({ email, password });
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onGetProfile = createAsyncThunk('auth/get-profile', async (tmp: any, thunkAPI) => {
    try {
        return await authService.getProfile();
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onRegister = createAsyncThunk('auth/register', async ({ firstName, lastName, email, password }: { firstName: string; lastName: string; email: string; password: string }, thunkAPI) => {
    try {
        return await authService.register({ firstName, lastName, email, password });
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onResetRegisterStates = createAsyncThunk('auth/resetRegisterStates', async () => {
    // This is a simple action to reset the states, so no need to do anything here
});

export const onResetLoginStates = createAsyncThunk('auth/resetLoginStates', async () => {
    // This is a simple action to reset the states, so no need to do anything here
});

export const onResetresendVerificationEmailStates = createAsyncThunk('auth/resetResendVerificationEmailStates', async () => {
    // This is a simple action to reset the states, so no need to do anything here
});

export const onCheckToken = createAsyncThunk('auth/onCheckToken', async () => {
    const token = await localStorage.getItem('access_token');
    if (token === null) return undefined;
    return token;
});

export const onForgotPassword = createAsyncThunk('auth/forgotPassword', async (email: string, thunkAPI) => {
    try {
        return await authService.forgotPassword(email);
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onResetPassword = createAsyncThunk('auth/resetPassword', async ({ token, newPassword }: { token: string; newPassword: string }, thunkAPI) => {
    try {
        return await authService.resetPassword({ token, newPassword });
    } catch (error) {
        return thunkAPI.rejectWithValue('ERROR:auth/resetPassword' + error);
    }
});

export const onResendVerificationEmail = createAsyncThunk('auth/resend-verification-email', async (email: string, thunkAPI) => {
    try {
        return await authService.resendVerificationEmail(email);
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const onLogout = createAsyncThunk('auth/logout', async (tmp: string, thunkAPI) => {
    try {
        await localStorage.removeItem('access_token');
    } catch (error) {
        return thunkAPI.rejectWithValue('ERROR:auth/logout' + error);
    }
});
