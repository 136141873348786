import { MESSAGES_FEATURES, NOTIFICATIONS_FEATURES, PROFILE_FEATURES, SETTINGS_FEATURES } from './types/Constants/Features';
import React, { useEffect, useRef } from 'react';
import { onLogout, onResendVerificationEmail, onResetresendVerificationEmailStates } from './store/features/auth/AuthAction';
import { useAppDispatch, useAppSelector } from './hooks/utils/ReduxUtil';

import AppMenu from './AppMenu';
import { Button } from 'primereact/button';
import { ROUTES } from './types/Routes';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { setAuthToken } from './api';
import { useNavigate } from 'react-router-dom';

const AppTopbar = (props: any) => {
    const dispatch = useAppDispatch();
    const { isLoggedIn, user, token, resendVerificationEmailSent, resendVerificationEmailError, resendVerificationEmailLoading } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();
    const onTopbarSubItemClick = (event: any) => {
        event.preventDefault();
    };

    const onLogoutClick = (event: any) => {
        event.preventDefault();
        dispatch(onLogout(''));
    };

    const onMyAdsClick = (event: any) => {
        event.preventDefault();
        navigate(ROUTES.USER_DASHBOARD);
    };

    const onResendVerificationEmailClick = (event: any) => {
        event.preventDefault();
        setAuthToken(token);
        if (user !== undefined) dispatch(onResendVerificationEmail(user.email));
    };

    useEffect(() => {
        if (resendVerificationEmailSent === true && user !== undefined) showSuccess();

        if (resendVerificationEmailError !== undefined && user !== undefined) showError();

        return () => {
            user! !== undefined && dispatch(onResetresendVerificationEmailStates());
        };
    }, [user, resendVerificationEmailError, resendVerificationEmailSent, dispatch]);

    const toast = useRef(null);

    const showSuccess = () => {
        //@ts-ignore
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Please Check Your Email', life: 3000 });
    };

    const showError = () => {
        //@ts-ignore
        toast.current.show({ severity: 'error', summary: 'Error', detail: resendVerificationEmailError, life: 5000 });
    };

    return (
        <>
            <Toast ref={toast} />
            {user !== undefined && user.isVerified === false && (
                <>
                    <div className="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
                        <div className="align-items-center hidden lg:flex">
                            <i className="pi pi-info-circle" style={{ color: 'red', marginRight: '4px' }}></i>
                            <span className="line-height-3  font-bold">&nbsp; Your account is not yet verified. Please check your email for the verification link. If you haven't received it.</span>
                        </div>
                        <button className="p-link flex align-items-center ml-2 mr-8 text-white" onClick={onResendVerificationEmailClick}>
                            {resendVerificationEmailLoading === true && <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.1rem' }}></i>}
                            <span className="underline font-bold">Click Here</span>
                            <span className="line-height-3 font-bold text-gray-100">&nbsp;to resend the verification email</span>
                        </button>
                        {/*<button
                            className="p-ripple p-link flex align-items-center no-underline justify-content-center border-circle text-gray-50 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150 p-ripple"
                            style={{ width: '2rem', height: '2rem' }}
                        >
                            <i className="pi pi-times"></i>
            </button>*/}
                    </div>
                </>
            )}

            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    <button className="logo p-link" onClick={() => navigate('/')}>
                        <img src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} alt="logo" />
                    </button>

                    {/*<button className="p-link" onClick={() => navigate('/')}>
                        <img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="app-name" alt="app-name" />
                    </button>*/}
                </div>

                <AppMenu
                    model={props.items}
                    menuMode={props.menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
                />

                {isLoggedIn && (
                    <div className="layout-topbar-right">
                        <ul className="layout-topbar-right-items">
                            <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
                                <button className="p-link" onClick={props.onTopbarItemClick}>
                                    <img src="assets/layout/images/profile-image.png" alt="profile" />
                                </button>

                                <ul className="fadeInDown">
                                    {PROFILE_FEATURES && (
                                        <li role="menuitem">
                                            <button className="p-link" onClick={onTopbarSubItemClick}>
                                                <i className="pi pi-fw pi-user"></i>
                                                <span>Profile</span>
                                            </button>
                                        </li>
                                    )}
                                    <li role="menuitem">
                                        <button className="p-link" onClick={onMyAdsClick}>
                                            <i className="pi pi-fw pi-table"></i>
                                            <span>My Ads</span>
                                        </button>
                                    </li>
                                    {SETTINGS_FEATURES && (
                                        <li role="menuitem">
                                            <button className="p-link" onClick={onTopbarSubItemClick}>
                                                <i className="pi pi-fw pi-cog"></i>
                                                <span>Settings</span>
                                            </button>
                                        </li>
                                    )}
                                    <li role="menuitem">
                                        <button className="p-link" onClick={onLogoutClick}>
                                            <i className="pi pi-fw pi-sign-out"></i>
                                            <span>Logout</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                            {NOTIFICATIONS_FEATURES && (
                                <li>
                                    <button className="p-link">
                                        <i className="topbar-icon pi pi-fw pi-bell"></i>
                                        <span className="topbar-badge">2</span>
                                        <span className="topbar-item-name">Notifications</span>
                                    </button>
                                </li>
                            )}
                            {MESSAGES_FEATURES && (
                                <li>
                                    <button className="p-link">
                                        <i className="topbar-icon pi pi-fw pi-comment"></i>
                                        <span className="topbar-badge">5</span>
                                        <span className="topbar-item-name">Messages</span>
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};

export default AppTopbar;
