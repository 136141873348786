import axios from 'axios';

export default class PhotoService {
    createThumbnailDataURL = (src: any, maxWidth: any, maxHeight: any) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = src;
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                const scalingFactor = Math.min(maxWidth / width, maxHeight / height);
                const canvas = document.createElement('canvas');
                canvas.width = width * scalingFactor;
                canvas.height = height * scalingFactor;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
                resolve(canvas.toDataURL());
            };
        });
    };

    getAdsImagesFromFiles(photos: any) {
        const galleriaDataPromises = photos.map(async (file: any) => {
            const previewImageSrc = URL.createObjectURL(file);
            const thumbnailImageSrc = await this.createThumbnailDataURL(previewImageSrc, 80, 60); // set desired thumbnail size
            return {
                previewImageSrc,
                thumbnailImageSrc,
                alt: file.name || '',
                title: file.name || ''
            };
        });

        return Promise.all(galleriaDataPromises);
    }

    getAdsImagesFromUrls(urls: any, maxWidth: any, maxHeight: any) {
        const galleriaDataPromises = urls.map(async (url: string) => {
            const previewImageSrc = url;
            const thumbnailImageSrc = await this.createThumbnailDataURL(previewImageSrc, maxWidth, maxHeight); // set desired thumbnail size
            return {
                previewImageSrc,
                thumbnailImageSrc,
                alt: '', // Since we don't have file.name, you can set it to an empty string or another value
                title: '' // Same as above
            };
        });

        return Promise.all(galleriaDataPromises);
    }

    getImages() {
        return axios.get('assets/demo/data/photos.json').then((res) => res.data.data);
    }
}

const images = [
    'https://storage.googleapis.com/ads-images-519389437601/64320d0b2e244cc16558a33c/643855939a23f85648d343f1/6532927d-9c86-45eb-91db-ef58455ab37e-image-0-c5.jpg',
    'https://storage.googleapis.com/ads-images-519389437601/64320d0b2e244cc16558a33c/643855939a23f85648d343f1/fd689956-eadf-41e1-b715-d6e2cd074271-image-1-c6.jpg'
];
