import { Field } from '../../../types/Fields/Field';
import { createSlice } from '@reduxjs/toolkit';
import { onGetFieldBySubCategory } from './FieldsAction';

const name = 'fields';

type State = {
    fields: Field[];
    fieldsLoading: boolean;
    fieldsError: string | undefined;
};

const initialState: State = {
    fields: [],
    fieldsLoading: false,
    fieldsError: undefined
};

export const fieldsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /// onGetBySubCategory
        builder.addCase(onGetFieldBySubCategory.pending, (state) => ({
            ...state,
            fieldsLoading: true
        }));
        builder.addCase(onGetFieldBySubCategory.fulfilled, (state, action) => ({
            ...state,
            fields: action.payload,
            fieldsLoading: false
        }));
        builder.addCase(onGetFieldBySubCategory.rejected, (state, { payload }) => ({
            ...state,
            fieldsLoading: false,
            //@ts-ignore
            fieldsError: payload.message
        }));
    }
});

export default fieldsSlice.reducer;
