import FileUploadService from '../../../service/FileUploadService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fileUploadService = new FileUploadService();

export const onFileUpload = createAsyncThunk('fileUpload/ads-images', async (images: any, thunkAPI) => {
    try {
        return await fileUploadService.fileUpload({ images: images });
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error);
    }
});
