import { Category } from '../../../types/Categories/Category';
import { createSlice } from '@reduxjs/toolkit';
import { onGetAll } from './CategoryAction';

const name = 'category';

type State = {
    categories: Category[];
    categoryLoading: boolean;
    categoryError: string | undefined;
};

const initialState: State = {
    categories: [],
    categoryLoading: false,
    categoryError: undefined
};

export const categorySlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /// getAll
        builder.addCase(onGetAll.pending, (state) => ({
            ...state,
            categoryLoading: true
        }));
        builder.addCase(onGetAll.fulfilled, (state, action) => ({
            ...state,
            categories: action.payload,
            categoryLoading: false
        }));
        builder.addCase(onGetAll.rejected, (state, { payload }) => ({
            ...state,
            categoryLoading: false,
            //@ts-ignore
            categoryError: payload.message
        }));
    }
});

export default categorySlice.reducer;
