import api from '../api';

export default class FileUploadService {
    fileUpload = async (images: any): Promise<any> => {
        try {
            const response = await api.post('/file-upload/ads-images', images);
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(error.response.data.message.message);
        }
    };
}
