export const enum ROUTES {
    HOME = '/',
    LIST_NEW_ADS = '/list-new-ads',
    USER_DASHBOARD = '/user-dashboard',
    LOGIN = '/ads-login',
    SIGN_UP = '/ads-signup',
    ADS_FEED = '/ads-feed',
    ADS_DETAILS = '/ad-details',
    ADS_SEARCH = '/search',
    RESET_PASSWORD = '/forgot-password',
    CHAT = '/chat'
}
