import { DataView, DataViewLayoutOptions, DataViewLayoutType } from 'primereact/dataview';
import React, { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { OrderList } from 'primereact/orderlist';
import { PickList } from 'primereact/picklist';
import ProductService from '../service/ProductService';
import { Rating } from 'primereact/rating';

const ListDemo = () => {
    const cities = [
        { name: 'Gafsa', code: 'NY' },
        { name: 'Jendouba', code: 'RM' },
        { name: 'Kairouan', code: 'LDN' },
        { name: 'Kasserine', code: 'IST' },
        { name: 'Ariana', code: 'NY' },
        { name: 'Beja', code: 'RM' },
        { name: 'Ben Arous', code: 'LDN' },
        { name: 'Bizerte', code: 'IST' },
        { name: 'Gabes', code: 'PRS' },
        { name: 'Zaghouan', code: 'NY' },
        { name: 'Tunis', code: 'RM' },
        { name: 'Tozeur', code: 'LDN' },
        { name: 'Tataouine', code: 'IST' },
        { name: 'Sousse', code: 'PRS' },
        { name: 'Siliana', code: 'NY' },
        { name: 'Sidi Bouzid', code: 'RM' },
        { name: 'Sfax', code: 'LDN' },
        { name: 'Nabeul', code: 'IST' },
        { name: 'Monastir', code: 'PRS' },
        { name: 'Medenine', code: 'NY' },
        { name: 'Manouba', code: 'RM' },
        { name: 'Mahdia', code: 'LDN' },
        { name: 'Kef', code: 'IST' },
        { name: 'Kebili', code: 'PRS' }
    ];

    const Categories = [
        { name: 'Real Estate', sub: ['Houses / Apartments for Sale', 'Houses / Apartments for Rent', 'Rooms for Rent', 'Office / Commercial Space', 'Land for Sale', 'Vacation Rentals', 'Real Estate Services'] },
        { name: 'Vehicles', sub: ['Cars', 'Motorcycles', 'Trucks / Commercial Vehicles', 'Auto Parts and Accessories', 'Boats', 'RVs / Campers / Caravans', 'Vehicle Services'] },
        { name: 'Electronics', sub: ['Mobile Phones / Tablets', 'Computers / Laptops', 'TV / Audio / Video', 'Cameras / Camcorders', 'Video Games / Consoles', 'Electronic Accessories'] },
        { name: 'Home & Garden', sub: ['Furniture', 'Appliances', 'Home Decor', 'Tools / Equipment', 'Garden / Outdoor Items', 'Household Services'] },
        { name: 'Fashion & Beauty', sub: ['Clothing / Accessories', 'Watches / Jewelry', 'Health / Beauty Products', 'Fashion Services'] },
        { name: 'Jobs', sub: ['Full-time Jobs', 'Part-time Jobs', 'Freelance / Remote Jobs', 'Internships', 'Job Seekers'] },
        { name: 'Services', sub: ['Home Services', 'Business Services', 'Events / Catering Services', 'Travel / Tourism Services', 'Education / Training Services', 'Health / Medical Services', 'Legal / Financial Services'] },
        { name: 'Pets', sub: ['Dogs / Cats', 'Birds', 'Fish', 'Other Pets', 'Pet Accessories', 'Pet Services'] },
        { name: 'Hobbies & Interests', sub: ['Sports / Fitness Equipment', 'Musical Instruments', 'Books / Magazines', 'Collectibles / Antiques', 'Art / Crafts', 'Tickets / Events'] },
        { name: 'Community', sub: ['Announcements / Events', 'Carpool / Rideshare', 'Classes / Workshops', 'Volunteers', 'Lost and Found'] }
    ];
    const listValue = [
        { name: 'San Francisco', code: 'SF' },
        { name: 'London', code: 'LDN' },
        { name: 'Paris', code: 'PRS' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Berlin', code: 'BRL' },
        { name: 'Barcelona', code: 'BRC' },
        { name: 'Rome', code: 'RM' }
    ];

    const [products, setProducts] = useState([]);
    const [citiesValue, setCitiesValue] = useState<any>(null);
    const [categoriesValue, setCategoriesValue] = useState<any>(null);
    const [loading1, setLoading1] = useState(false);

    const [dataviewValue, setDataviewValue] = useState<any>(null);
    const [layout, setLayout] = useState<DataViewLayoutType>('grid');
    const [sortKey, setSortKey] = useState<any>(null);
    const [sortOrder, setSortOrder] = useState<any>(null);
    const [sortField, setSortField] = useState<any>(null);

    const sortOptions = [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
    ];

    const carouselResponsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then((data) => setDataviewValue(data));
    }, []);

    useEffect(() => {
        const productService = new ProductService();
        productService.getProductsSmall().then((products) => setProducts(products));
    }, []);

    const onLoadingClick = () => {
        setLoading1(true);

        setTimeout(() => {
            setLoading1(false);
        }, 2000);
    };
    const onSortChange = (event: any) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    };

    const carouselItemTemplate = (product: any) => {
        // console.log(product);
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mb-3">
                        <img src={`assets/demo/images/product/${product.image}`} alt={product.name} className="product-image" />
                    </div>
                    <div>
                        <h4 className="p-mb-1">{product.name}</h4>
                    </div>
                </div>
            </div>
        );
    };

    const dataviewHeader = (
        <div className="grid grid-nogutter">
            <div className="col-6" style={{ textAlign: 'left' }}>
                <Dropdown value={sortKey} options={sortOptions} optionLabel="label" placeholder="Sort By Price" onChange={onSortChange} />
            </div>
            <div className="col-6" style={{ textAlign: 'right' }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        </div>
    );

    const dataviewListItem = (data: any) => {
        return (
            <div className="col-12 list-demo">
                <div className="product-list-item">
                    <img src={`assets/demo/images/product/${data.image}`} alt={data.name} />
                    <div className="product-list-detail">
                        <div className="product-name">{data.name}</div>
                        <div className="product-description">{data.description}</div>
                        <Rating value={data.rating} readOnly cancel={false}></Rating>
                        <i className="pi pi-tag product-category-icon"></i>
                        <span className="product-category">{data.category}</span>
                    </div>
                    <div className="product-list-action">
                        <span className="product-price">${data.price}</span>
                        <Button icon="pi pi-shopping-cart" label="Add to Cart" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button>
                        <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span>
                    </div>
                </div>
            </div>
        );
    };

    const dataviewGridItem = (data: any) => {
        return (
            <div className="col-12 md:col-4">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category">{data.category}</span>
                        </div>
                        <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span>
                    </div>
                    <div className="product-grid-item-content">
                        <img src={`assets/demo/images/product/${data.image}`} alt={data.name} />
                        <div className="product-name">{data.name}</div>
                        <div className="product-description">{data.description}</div>
                        <Rating value={data.rating} readOnly cancel={false}></Rating>
                    </div>
                    <div className="product-grid-item-bottom">
                        <span className="product-price">${data.price}</span>
                        <Button icon="pi pi-shopping-cart" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (data: any, layout: string) => {
        if (!data) {
            return;
        }

        if (layout === 'list') {
            return dataviewListItem(data);
        } else if (layout === 'grid') {
            return dataviewGridItem(data);
        }
    };

    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Des millions de petites annonces et autant d’occasions de se faire plaisir</h5>
                    <div className="col-1 md:col-12">
                        <div className="p-inputgroup">
                            <Dropdown value={citiesValue} onChange={(e) => setCitiesValue(e.value)} options={Categories} optionLabel="name" placeholder="Categories" />
                            <InputText placeholder="Search on SookElMaghreb" />
                            <Dropdown value={categoriesValue} onChange={(e) => setCategoriesValue(e.value)} options={cities} optionLabel="name" placeholder="AllCities" />
                            <Button label="Search" icon="pi pi-search" loading={loading1} onClick={onLoadingClick} />
                        </div>
                    </div>
                    <div className="grid p-fluid media-demo">
                        <div className="col-12">
                            <div className="card">
                                <h5>Top Categories</h5>
                                <Carousel autoplayInterval={3000} circular value={products} numVisible={3} numScroll={3} responsiveOptions={carouselResponsiveOptions} itemTemplate={carouselItemTemplate}></Carousel>
                            </div>
                        </div>
                    </div>

                    <DataView value={dataviewValue} layout={layout} paginator rows={9} sortOrder={sortOrder} sortField={sortField} itemTemplate={itemTemplate} header={dataviewHeader}></DataView>
                </div>
            </div>
        </div>
    );
};

export default ListDemo;
