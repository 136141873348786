import { Field } from '../../../types/Fields/Field';
import { createSlice } from '@reduxjs/toolkit';
import { onFileUpload } from './FileUploadAction';

const name = 'fileUpload';

type State = {
    gsImagesUris: string[];
    uploadLoading: boolean;
    uploadError: string | undefined;
};

const initialState: State = {
    gsImagesUris: [],
    uploadLoading: false,
    uploadError: undefined
};

export const fileUploadSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /// onFileUpload
        builder.addCase(onFileUpload.pending, (state) => ({
            ...state,
            uploadLoading: true
        }));
        builder.addCase(onFileUpload.fulfilled, (state, action) => ({
            ...state,
            gsImagesUris: action.payload,
            uploadLoading: false
        }));
        builder.addCase(onFileUpload.rejected, (state, { payload }) => ({
            ...state,
            uploadLoading: false,
            //@ts-ignore
            uploadError: payload.message
        }));
    }
});

export default fileUploadSlice.reducer;
