import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import { onLogin, onResetLoginStates } from '../../store/features/auth/AuthAction';
import { useAppDispatch, useAppSelector } from '../../hooks/utils/ReduxUtil';

import AuthService from '../../service/AuthService';
import BlockViewer from '../../BlockViewer';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import ForgotPasswordForm from './ForgotPasswordForm';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ROUTES } from '../../types/Routes';
import SignUpForm from './SignUpForm';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';

export const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [showMessage, setShowMessage] = useState(false);
    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { user, loginLoading, loginError, isLoggedIn } = useAppSelector((state) => state.auth);
    const toast = useRef(null);
    const defaultValues = {
        email: '',
        password: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({ defaultValues });

    const onSubmit = () => {
        dispatch(onLogin({ email: email, password: password }));
        setShowMessage(true);
        reset();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    useEffect(() => {
        if (user) {
            setTimeout(() => {
                setSignUpDialog(false);
                navigate(ROUTES.HOME);
            }, 0);
        }
    }, [user, navigate]);

    const [signUpDialog, setSignUpDialog] = useState(false);
    const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);

    const hideSignUpDialog = () => {
        setSignUpDialog(false);
    };

    const hideForgotPasswordDialog = () => {
        setForgotPasswordDialog(false);
    };

    const SignUpDialog = () => {
        return (
            <Dialog visible={signUpDialog} style={{ width: '600px' }} header="Sign Up" modal draggable={false} blockScroll={true} onHide={hideSignUpDialog}>
                <SignUpForm />
            </Dialog>
        );
    };

    const ForgotPasswordDialog = () => {
        return (
            <Dialog visible={forgotPasswordDialog} style={{ width: '600px' }} header="Reset Password" modal draggable={false} blockScroll={true} onHide={hideForgotPasswordDialog}>
                <ForgotPasswordForm />
            </Dialog>
        );
    };

    const showSuccess = () => {
        user !== undefined && toast.current.show({ severity: 'success', summary: 'Success', detail: 'Succefully Logged In', life: 3000 });
    };

    const showError = () => {
        loginError !== undefined && toast.current.show({ severity: 'error', summary: 'Error', detail: loginError, life: 5000 });
    };

    useEffect(() => {
        if (user !== undefined) {
            showSuccess();
        }

        if (loginError !== undefined) {
            showError();
        }

        return () => {
            dispatch(onResetLoginStates());
        };
    }, [user, loginError, dispatch]);

    return (
        <>
            <Toast ref={toast} />
            <BlockViewer header="Sign-In" code={null} containerClassName="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
                <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                    <div className="text-center mb-5">
                        <img src="assets/demo/images/blocks/logos/hyper.png" alt="hyper" height="50" className="mb-3" />
                        <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                        <span className="text-600 font-medium line-height-3">Don't have an account?</span>
                        <button className="p-link font-medium no-underline ml-2 text-blue-500 cursor-pointer" onClick={(e) => setSignUpDialog(true)}>
                            Create today!
                        </button>
                    </div>

                    <div>
                        <form>
                            <label htmlFor="email1" className="block text-900 font-medium mb-2">
                                Email
                            </label>
                            <InputText id="email1" type="text" className="w-full mb-3" value={email} onChange={(e) => setEmail(e.target.value)} />

                            <label htmlFor="password1" className="block text-900 font-medium mb-2">
                                Password
                            </label>
                            <InputText id="password1" type="password" className="w-full mb-3" value={password} onChange={(e) => setPassword(e.target.value)} />

                            <div className="flex align-items-center justify-content-between mb-6">
                                <div className="flex align-items-center">
                                    <Checkbox inputId="rememberme1" className="mr-2" onChange={(e) => setChecked(e.checked)} checked={checked} />
                                    <label htmlFor="rememberme1">Remember me</label>
                                </div>
                                <button className="p-link font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" onClick={(e) => setForgotPasswordDialog(true)}>
                                    Forgot password?
                                </button>
                            </div>

                            <Button
                                type="submit"
                                label="Sign In"
                                icon="pi pi-user"
                                className="w-full"
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={loginLoading}
                            />
                        </form>
                    </div>
                </div>
            </BlockViewer>
            {SignUpDialog()}
            {ForgotPasswordDialog()}
        </>
    );
};

export default LoginForm;
