import { Category } from '../types/Categories/Category';
import api from '../api';

export default class CategoryService {
    getAll = async (): Promise<Category[]> => {
        try {
            const response = await api.get<Category[]>('/categories/all');
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error('Error Fetching Category');
        }
    };

    findByName = async (name: string): Promise<Category> => {
        try {
            const response = await api.get<Category>(`/categories/find/${name}`);
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error('Error Fetching Category');
        }
    };

    createCategory = async (category: any) => {
        const response = await api.post('/categories', category);
        return response.data;
    };

    createSubCategory = async (subCategory: any) => {
        const response = await api.post('/subcategories', subCategory);
        return response.data;
    };

    createField = async (field: any) => {
        const response = await api.post('/fields', field);
        return response.data;
    };
}
