import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { onCheckToken, onForgotPassword, onGetProfile, onLogin, onLogout, onRegister, onResendVerificationEmail, onResetLoginStates, onResetPassword, onResetRegisterStates, onResetresendVerificationEmailStates } from './AuthAction';

import { LoginResponse } from '../../../types/response/Response';
import { User } from '../../../types/user/User';

const name = 'auth';

type State = {
    user: User | undefined;
    isLoggedIn: boolean;
    token: string | undefined;
    resendVerificationEmailSent: boolean;
    loginLoading: boolean;
    logoutLoading: boolean;
    registerLoading: boolean;
    forgotPasswordEmailSent: boolean;
    forgotPasswordLoading: boolean;
    resetPasswordLoading: boolean;
    resendVerificationEmailLoading: boolean;
    resendVerificationEmailError: string | undefined;
    loginError: string | undefined;
    registerError: string | undefined;
    forgotPasswordError: string | undefined;
    resetPasswordError: string | undefined;
};

const initialState: State = {
    user: undefined,
    isLoggedIn: false,
    token: undefined,
    resendVerificationEmailSent: false,
    forgotPasswordEmailSent: false,

    // loading
    loginLoading: false,
    logoutLoading: false,
    registerLoading: false,
    forgotPasswordLoading: false,
    resetPasswordLoading: false,
    resendVerificationEmailLoading: false,

    // error
    resendVerificationEmailError: undefined,
    loginError: undefined,
    registerError: undefined,
    forgotPasswordError: undefined,
    resetPasswordError: undefined
};

export const authSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /// register
        builder.addCase(onRegister.pending, (state) => ({
            ...state,
            registerLoading: true
        }));
        builder.addCase(onRegister.fulfilled, (state, action) => ({
            ...state,
            user: action.payload.user,
            registerLoading: false
        }));
        builder.addCase(onRegister.rejected, (state, { payload }) => ({
            ...state,
            registerLoading: false,
            //@ts-ignore
            registerError: payload.message
        }));

        /// Login
        builder.addCase(onLogin.pending, (state) => ({
            ...state,
            loginLoading: true
        }));
        builder.addCase(onLogin.fulfilled, (state, action: PayloadAction<LoginResponse>) => ({
            ...state,
            user: action.payload.user,
            isLoggedIn: action.payload.access_token !== undefined && action.payload.user?.isVerified === true ? true : false,
            token: action.payload.access_token,
            loginLoading: false
        }));
        builder.addCase(onLogin.rejected, (state, { payload }) => ({
            ...state,
            loginLoading: false,
            //@ts-ignore
            loginError: payload.message
        }));

        /// getProfile
        builder.addCase(onGetProfile.pending, (state) => ({
            ...state,
            loginLoading: true
        }));
        builder.addCase(onGetProfile.fulfilled, (state, action: PayloadAction<LoginResponse>) => ({
            ...state,
            user: action.payload.user,
            isLoggedIn: action.payload.access_token !== undefined && action.payload.user?.isVerified === true ? true : false,
            token: action.payload.access_token,
            loginLoading: false
        }));
        builder.addCase(onGetProfile.rejected, (state, { payload }) => ({
            ...state,
            loginLoading: false,
            //@ts-ignore
            loginError: payload.message
        }));

        /// logout

        builder.addCase(onLogout.pending, (state) => ({
            ...state,
            logoutLoading: true
        }));
        builder.addCase(onLogout.fulfilled, (state, action) => ({
            ...state,
            user: undefined,
            isLoggedIn: false,
            token: undefined,
            logoutLoading: false
        }));
        builder.addCase(onLogout.rejected, (state, { error }) => ({
            ...state,
            logoutLoading: false
        }));

        /// forgotPassword

        builder.addCase(onForgotPassword.pending, (state) => ({
            ...state,
            forgotPasswordLoading: true
        }));
        builder.addCase(onForgotPassword.fulfilled, (state, action) => ({
            ...state,
            forgotPasswordEmailSent: true,
            forgotPasswordLoading: false
        }));
        builder.addCase(onForgotPassword.rejected, (state, { error }) => ({
            ...state,
            forgotPasswordLoading: false,
            forgotPasswordError: error.message
        }));

        /// resetPassword

        builder.addCase(onResetPassword.pending, (state) => ({
            ...state,
            resetPasswordLoading: true
        }));
        builder.addCase(onResetPassword.fulfilled, (state, action) => ({
            ...state,
            resetPasswordLoading: false
        }));
        builder.addCase(onResetPassword.rejected, (state, { error }) => ({
            ...state,
            resetPasswordLoading: false,
            resetPasswordError: error.message
        }));

        /// reset register state
        builder.addCase(onResetRegisterStates.fulfilled, (state) => {
            state.registerError = undefined;
        });

        /// reset login state
        builder.addCase(onResetLoginStates.fulfilled, (state) => {
            state.loginError = undefined;
        });

        /// reset resend verification email state
        builder.addCase(onResetresendVerificationEmailStates.fulfilled, (state) => {
            state.resendVerificationEmailSent = false;
            state.resendVerificationEmailError = undefined;
        });
        /// check local storage login token
        builder.addCase(onCheckToken.fulfilled, (state, action) => {
            state.isLoggedIn = action.payload !== undefined;
            state.token = action.payload;
        });

        /// resend verification email state
        builder.addCase(onResendVerificationEmail.pending, (state) => {
            state.resendVerificationEmailLoading = true;
        });

        builder.addCase(onResendVerificationEmail.fulfilled, (state) => {
            state.resendVerificationEmailSent = true;
            state.resendVerificationEmailLoading = false;
        });

        builder.addCase(onResendVerificationEmail.rejected, (state, { payload }) => {
            state.resendVerificationEmailLoading = false;
            //@ts-ignore
            state.resendVerificationEmailError = payload.message;
        });
    }
});

export const {} = authSlice.actions;
export default authSlice.reducer;
