import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/utils/ReduxUtil';

import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { CHAT_FEATURES } from '../../types/Constants/Features';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Chip } from 'primereact/chip';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Galleria } from 'primereact/galleria';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import PhotoService from '../../service/PhotoService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ROUTES } from '../../types/Routes';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { cities } from '../../types/Constants/Cities';
import { classNames } from 'primereact/utils';
import { createNewObjectWithKeysAtRoot } from '../../utilities/Utils';
import { onAdsCreate } from '../../store/features/ads/AdsAction';
import { onCheckToken } from '../../store/features/auth/AuthAction';
import { onGetAll } from '../../store/features/category/CategoryAction';
import { onGetFieldBySubCategory } from '../../store/features/field/FieldsAction';
import { setAuthToken } from '../../api';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const ListNewAds = () => {
    useAuth();

    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { token } = useAppSelector((state) => state.auth);
    const { createdAd, createdAdLoading, createdAdError } = useAppSelector((state) => state.ads);
    const { categories, categoryError, categoryLoading } = useAppSelector((state) => state.category);
    const { fields, fieldsError, fieldsLoading } = useAppSelector((state) => state.fields);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryName, setSelectedCategoryName] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [galleriaImages, setGalleriaImages] = useState([]);
    const [defaultValues, setDefaultValues] = useState({});
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        dispatch(onCheckToken());
        if (!categories.length) dispatch(onGetAll(''));
    }, []);

    /*useEffect(() => {

        console.log("calling subCategoryTemplate",subCategoryTemplate)
        setDefaultValues({
            ...subCategoryTemplate.reduce((obj, field) => {
                let defaultValue;
                switch (field.type) {
                    case 'text':
                    case 'textarea':
                        defaultValue = '';
                        break;
                    case 'select':
                    case 'checkbox':
                        defaultValue = [];
                        break;
                    case 'number':
                        defaultValue = 0;
                        break;
                    default:
                        defaultValue = null;
                        break;
                }
                obj[field.name] = defaultValue;
                return obj;
            }, {}),
            title: '',
            description: '',
            category: '',
            subCategory: '',
            contactfirstName: '',
            contactLastName: '',
            contactPhoneNumber: '',
            contactEmail: '',
            contactAddress: '',
            contactCity: '',
            'contactZip/PostalCode': '',
            photos: []
        });
    }, [subCategoryTemplate]);*/

    useEffect(() => {
        if (selectedCategory && selectedSubCategory) {
            console.log('useEffect called with', selectedCategory, selectedSubCategory);
            dispatch(onGetFieldBySubCategory(selectedSubCategory));
        }
    }, [selectedCategory, selectedSubCategory]);

    const toast = useRef(null);
    const stepsItems = [
        {
            label: 'Category',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Choose Category', detail: event.item.label });
            }
        },
        {
            label: 'Description',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Add Description', detail: event.item.label });
            }
        },
        {
            label: 'Details',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Add Details', detail: event.item.label });
            }
        },
        {
            label: 'Images',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Add Images', detail: event.item.label });
            }
        },
        {
            label: 'Contact ',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Add Contact Details', detail: event.item.label });
            }
        },
        {
            label: 'Preview ',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Preview Listing', detail: event.item.label });
            }
        }
    ];

    const [termAndConditionsChecked, setTermAndConditionsChecked] = useState(false);
    const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
    const [formData, setFormData] = useState({});

    const [activeIndex, setActiveIndex] = useState(0);
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        watch,
        trigger,
        getValues
    } = useForm({ defaultValues });
    const selectedPhotosFiles = watch('photos');

    useEffect(() => {
        //reset(defaultValues);
    }, [defaultValues, reset]);

    const handleChange = (e, key) => {};

    const onCategoryChange = (value) => {
        console.log(value)
        setSelectedCategory(value._id);
        setSelectedCategoryName(value.categoryName)
        setSelectedSubCategory(null);
        setSubCategories(value.sub);
    };

    const onSubCategoryChange = (value) => {
        setSelectedSubCategory(value);
    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmitForm = async (data) => {
        setAuthToken(token);
        const keysToKeepAtRoot = ['category', 'subCategory', 'city', 'title', 'description', 'contactFirstName', 'contactLastName', 'contactPhoneNumber', 'contactEmail', 'contactAddress', 'contactCity', 'contactPostalCode', 'photos'];
        data['category'] = selectedCategory;
        data['subCategory'] = selectedSubCategory;

        const newObject = createNewObjectWithKeysAtRoot(data, keysToKeepAtRoot);
        const formData_1 = new FormData();
        const finalObj = {
            title: newObject.title,
            description: newObject.description,
            subCategoryId: newObject.subCategory,
            categoryId: newObject.category,
            location: newObject.city.name,
            contactDetails: {
                firstName: newObject.contactFirstName,
                lastName: newObject.contactLastName,
                phoneNumber: newObject.contactPhoneNumber,
                email: newObject.contactEmail,
                address: newObject.contactAddress,
                city: newObject.contactCity === undefined ? null : newObject.contactCity.name,
                postalCode: newObject.contactPostalCode
            },
            fields: { ...newObject.customFields },
            images: newObject.photos
        };
        // Append all non-file fields
        Object.keys(finalObj).forEach((key) => {
            if (key !== 'images' && key !== 'contactDetails' && key !== 'fields') {
                formData_1.append(key, finalObj[key]);
            }

            if (key === 'contactDetails' || key === 'fields') {
                formData_1.append(key, JSON.stringify(finalObj[key]));
            }
        });
        // Append the images
        finalObj.images.forEach((image, index) => {
            formData_1.append('images', image, `image-${index}-${image.name}`);
        });

        dispatch(onAdsCreate(formData_1))
        setFormData(data);

        // reset();
    };
    useEffect(() => {
        if (createdAd !== undefined && createdAdLoading === false && createdAdError === undefined) {
           navigate(`/${selectedCategoryName.toLowerCase()}/${createdAd._id}`, 
            { state: { 
                category: selectedCategoryName.toLowerCase(), 
                subCategoryId: selectedSubCategory, 
                adId: createdAd._id } 
            }
            )
        }
    }, [createdAd, createdAdLoading, createdAdError]);

    useEffect(() => {
        if (selectedPhotosFiles) {
            const photoService = new PhotoService();
            photoService.getAdsImagesFromFiles(selectedPhotosFiles).then((images) => setGalleriaImages(images));
        }
    }, [selectedPhotosFiles]);

    useEffect(() => {
        if (createdAd !== undefined && createdAdError !== undefined) {
            setTimeout(() => {
                navigate(`/${selectedCategoryName}/${createdAd._id}`, { state: { category: selectedCategoryName, adId: createdAd._id } });
            }, 2000);
        }
    }, [createdAd, navigate]);

    const onTermAndConditionsCheckboxChange = (e) => {
        setTermAndConditionsChecked(e.checked);
    };

    const onPrivacyPolicyCheckboxChange = (e) => {
        setPrivacyPolicyChecked(e.checked);
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    const RenderSelectCategory = () => {
        return (
            <div className="col-12">
                <div className="card">
                    <div className="p-fluid fromgrid grid">
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <Controller
                                    name="category"
                                    control={control}
                                    rules={{ required: 'Category is required.' }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            id={field.name}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                onCategoryChange(e.target.value);
                                            }}
                                            options={categories.map((item) => ({
                                                categoryName: item.name,
                                                _id: item._id,
                                                sub: item.subCategories.map((subCategory) => ({
                                                    label: subCategory.name,
                                                    value: subCategory._id
                                                }))
                                            }))}
                                            optionLabel="categoryName"
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                        />
                                    )}
                                />

                                <label htmlFor="category" className={classNames({ 'p-error': errors.category })}>
                                    Category
                                </label>
                            </span>
                            {getFormErrorMessage('category')}
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <Controller
                                    name="subCategory"
                                    control={control}
                                    rules={{ required: 'SubCategory is required.' }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            id={field.name}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                onSubCategoryChange(e.target.value);
                                            }}
                                            options={subCategories}
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                        />
                                    )}
                                />
                                <label htmlFor="subCategory" className={classNames({ 'p-error': errors.subCategory })}>
                                    Sub Category
                                </label>
                            </span>
                            {getFormErrorMessage('subCategory')}
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label">
                                <Controller
                                    name="city"
                                    control={control}
                                    rules={{ required: 'City is required.' }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            id={field.name}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                // onSubCategoryChange(e.target.value);
                                            }}
                                            optionLabel="name"
                                            options={cities}
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                        />
                                    )}
                                />
                                <label htmlFor="city" className={classNames({ 'p-error': errors.city })}>
                                    City
                                </label>
                            </span>
                            {getFormErrorMessage('city')}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const RenderDescription = () => {
        return (
            <>
                <div className="field col-12">
                    <span className="p-float-label">
                        <Controller
                            name="title"
                            control={control}
                            rules={{ required: 'Title is required.' }}
                            render={({ field, fieldState }) => <InputText id={field.name} value={field.value} onChange={(e) => field.onChange(e)} {...field} className={classNames({ 'p-invalid': fieldState.error })} />}
                        />
                        <label htmlFor="title" className={classNames({ 'p-error': errors['title'] })}>
                            Title*
                        </label>
                    </span>
                    {getFormErrorMessage('title')}
                </div>

                <div className="field col-12">
                    <span className="p-float-label">
                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: 'Description is required' }}
                            render={({ field, fieldState }) => <InputTextarea id={field.name} {...field} autoResize rows={5} cols={30} className={classNames({ 'p-invalid': fieldState.invalid })} />}
                        />
                        <label htmlFor="description" className={classNames({ 'p-error': errors['description'] })}>
                            Description*
                        </label>
                    </span>
                    {getFormErrorMessage('description')}
                </div>
            </>
        );
    };

    const RenderCategoryTemplate = () => {
        return fields.map((cfield, index) => {
            switch (cfield.type) {
                case 'text':
                    return (
                        <div className="field col-6" key={index}>
                            <span className="p-float-label">
                                <Controller name={cfield.name} control={control} render={({ field, fieldState }) => <InputText id={field.name} {...field} ref={field.ref} className={classNames({ 'p-invalid': fieldState.invalid })} />} />
                                <label htmlFor={cfield.name} className={classNames({ 'p-error': errors[cfield.name] })}>
                                    {cfield.label}
                                </label>
                            </span>
                        </div>
                    );

                case 'email':
                    return (
                        <div className="field col-6 md:col-6" key={index}>
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller
                                    name={cfield.name}
                                    control={control}
                                    rules={{ required: `${cfield.label} is required.`, pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' } }}
                                    render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />}
                                />
                                <label htmlFor={cfield.name} className={classNames({ 'p-error': errors[cfield.name] })}>
                                    {cfield.label}
                                </label>
                            </span>
                            {getFormErrorMessage(cfield.name)}
                        </div>
                    );
                case 'textarea':
                    return (
                        <div className="field col-12" key={index}>
                            <span className="p-float-label">
                                <Controller
                                    name={cfield.name}
                                    control={control}
                                    render={({ field, fieldState }) => <InputTextarea id={field.name} {...field} autoResize rows={5} cols={30} className={classNames({ 'p-invalid': fieldState.invalid })} />}
                                />
                                <label htmlFor={cfield.name} className={classNames({ 'p-error': errors[cfield.name] })}>
                                    {cfield.label}
                                </label>
                            </span>
                        </div>
                    );
                case 'number':
                    return (
                        <div className="field col-12 md:col-3" key={index}>
                            <span className="p-float-label">
                                <Controller
                                    name={cfield.name}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            id={field.name}
                                            inputRef={field.ref}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            onValueChange={(e) => field.onChange(e)}
                                            useGrouping={false}
                                            inputClassName={classNames({ 'p-invalid': fieldState.error })}
                                        />
                                    )}
                                />
                                <label htmlFor={cfield.name} className={classNames({ 'p-error': errors[cfield.name] })}>
                                    {cfield.label}
                                </label>
                            </span>
                        </div>
                    );
                case 'select':
                    return (
                        <div className="field col-12 md:col-3" key={index}>
                            <span className="p-float-label">
                                <Controller name={cfield.name} control={control} render={({ field, fieldState }) => <Dropdown id={field.name} {...field} options={cfield.options} className={classNames({ 'p-invalid': fieldState.invalid })} />} />
                                <label htmlFor={cfield.name} className={classNames({ 'p-error': errors[cfield.name] })}>
                                    {cfield.label}
                                </label>
                            </span>
                        </div>
                    );
                case 'checkbox':
                    return (
                        <div className="field col-12 md:col-6" key={index}>
                            <span className="p-float-label">
                                <Controller
                                    name={cfield.name}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <MultiSelect
                                            id={field.name}
                                            {...field}
                                            options={cfield.options.map((option) => ({
                                                label: option,
                                                value: option
                                            }))}
                                            filter
                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                        />
                                    )}
                                />
                                <label htmlFor={cfield.name} className={classNames({ 'p-error': errors[cfield.name] })}>
                                    {cfield.label}
                                </label>
                            </span>
                        </div>
                    );
                case 'daterange':
                    return (
                        <div className="p-field" key={index}>
                            <label htmlFor={cfield.name}>{cfield.label}</label>
                            <div className="p-inputgroup">
                                <Calendar id={`${cfield.name}_start`} name={`${cfield.name}_start`} value={formData[`${cfield.name}_start`] || null} onChange={(e) => handleChange(e, `${cfield.name}_start`)} dateFormat="dd/mm/yy" />
                                <span className="p-inputgroup-addon">to</span>
                                <Calendar id={`${cfield.name}_end`} name={`${cfield.name}_end`} value={formData[`${cfield.name}_end`] || null} onChange={(e) => handleChange(e, `${cfield.name}_end`)} dateFormat="dd/mm/yy" />
                            </div>
                        </div>
                    );

                default:
                    return null;
            }
        });
    };

    const ContactDetails = () => {
        return (
            <div className="col-12">
                <div className="card">
                    <div className="p-fluid formgrid grid">
                        <div className="field col-6 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                                <Controller
                                    name="contactFirstName"
                                    control={control}
                                    rules={{ required: 'First Name  is required' }}
                                    render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />}
                                />
                                <label htmlFor="contactFirstName" className={classNames({ 'p-error': errors.contactFirstName })}>
                                    First Name
                                </label>
                            </span>
                            {getFormErrorMessage('contactFirstName')}
                        </div>

                        <div className="field col-6 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-user" />
                                <Controller
                                    name="contactLastName"
                                    control={control}
                                    rules={{ required: 'Last Name  is required' }}
                                    render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />}
                                />
                                <label htmlFor="contactLastName" className={classNames({ 'p-error': errors.contactLastName })}>
                                    Last Name
                                </label>
                            </span>
                            {getFormErrorMessage('contactLastName')}
                        </div>

                        <div className="field col-6 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-envelope" />
                                <Controller
                                    name="contactEmail"
                                    control={control}
                                    rules={{ required: 'Email is required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' } }}
                                    render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />}
                                />
                                <label htmlFor="contactEmail" className={classNames({ 'p-error': errors.contactEmail })}>
                                    Email
                                </label>
                            </span>
                            {getFormErrorMessage('contactEmail')}
                        </div>

                        <div className="field col-6 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-phone" />
                                <Controller
                                    name="contactPhoneNumber"
                                    control={control}
                                    rules={{ required: 'Mobile number is required.', pattern: { value: /^\+?[1-9]\d{1,14}$/, message: 'Invalid mobile number.' } }}
                                    render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />}
                                />
                                <label htmlFor="contactPhoneNumber" className={classNames({ 'p-error': errors.contactPhoneNumber })}>
                                    Phone Number
                                </label>
                            </span>
                            {getFormErrorMessage('contactPhoneNumber')}
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-home" />
                                <Controller name="contactAddress" control={control} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />} />
                                <label htmlFor="contactAddress" className={classNames({ 'p-error': errors.contactAddress })}>
                                    Address
                                </label>
                            </span>
                            {getFormErrorMessage('contactAddress')}
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <Controller
                                    name="contactCity"
                                    control={control}
                                    render={({ field, fieldState }) => <Dropdown id={field.name} {...field} options={cities} optionLabel="name" placeholder="Select One" className={classNames({ 'p-invalid': fieldState.invalid })} />}
                                />
                                <label htmlFor="contactCity" className={classNames({ 'p-error': errors.contactCity })}>
                                    City
                                </label>
                            </span>
                            {getFormErrorMessage('contactCity')}
                        </div>

                        <div className="field col-12 md:col-6">
                            <span className="p-float-label p-input-icon-left">
                                <Controller name="contactPostalCode" control={control} render={({ field, fieldState }) => <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />} />
                                <label htmlFor="contactPostalCode" className={classNames({ 'p-error': errors.contactPostalCode })}>
                                    Zip/Postal Code
                                </label>
                            </span>
                            {getFormErrorMessage('contactPostalCode')}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const previewListing = (photos) => {
        const formValues = watch();

        const toggleExpanded = (e) => {
            e.preventDefault()
            setExpanded(!expanded);
        };

        ///// so so fucking ugly
        const mapCriteria = () => {
            return fields
                .filter((field) => !['title', 'description', 'price', 'address', 'city'].includes(field.name))
                .sort((a, b) => {
                    return a.options.length === 0 ? -1 : 1;
                })
                .map((field, index) => {
                    return !Array.isArray(formValues[field.name]) ? (
                        <div key={index} className="col-12 md:col-6 lg:col-3">
                            <div className="surface-card shadow-2 p-3 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">{field.label}</span>
                                        <div className="text-900 font-medium text-xl">{formValues[field.name] || ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div key={index} className="col-12 md:col-6 lg:col-3">
                            <div className="surface-card shadow-2 p-3 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">{field.label}</span>

                                        <div className="grid">
                                            {formValues[field.name].map((opt, optIndex) => (
                                                <Chip key={optIndex} label={opt} className="mr-2 mb-2" />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
        };

        const formatUserInput = (input) => {
            // Normalize multiple consecutive newline characters
            let normalizedInput = input.replace(/\n{3,}/g, '\n\n');

            // Normalize multiple consecutive spaces
            normalizedInput = normalizedInput.replace(/ +/g, ' ');

            // Remove leading and trailing whitespace
            normalizedInput = normalizedInput.trim();

            return normalizedInput.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ));
        };

        const getInitials = (firstName, lastName) => {
            return (firstName.slice(0, 1) + lastName.slice(0, 1)).toUpperCase();
        };

        const responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '960px',
                numVisible: 4
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];

        const itemTemplate = (item) => {
            return <img src={item.previewImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
        };

        const thumbnailTemplate = (item) => {
            return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
        };

        return (
            <div className="grid">
                
                <div className="col-12 md:col-6">
                    <div className="card">
                        <Galleria value={photos} responsiveOptions={responsiveOptions} numVisible={5} circular style={{ maxWidth: '640px' }} showItemNavigators showItemNavigatorsOnHover item={itemTemplate} thumbnail={thumbnailTemplate} />
                    </div>

                    <Card title={<h2>{formatUserInput(formValues['title'])}</h2>} subTitle={<h5>6 Pièces · 146 m² · Grand-Aigueblanche 73260</h5>}>
                        <div className="text-green-500 font-medium text-xl">{formValues['price']} TND</div>
                        <Divider />

                        <Card title={<h4>Description</h4>} subTitle="01/10/2023 10:39 PM" style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
                            <p>{expanded ? formatUserInput(formValues['description']) : formatUserInput(formValues['description'].slice(0, 500) + '...')}</p>
                            <Button label={expanded ? 'Read Less' : 'Read More'} className="p-button-text" onClick={(e) => toggleExpanded(e)} />
                        </Card>
                        <Divider />

                        <Card title={<h4>Critiria</h4>} style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
                            <div className="grid">{mapCriteria()}</div>
                        </Card>
                    </Card>
                </div>

                <div className="col-12 md:col-6">
                    <div className="card">
                        <h5>Contact</h5>
                        <Panel
                            header={
                                <div className="grid   flex align-items-center justify-content-center">
                                    <div className="col">
                                        <Avatar label={getInitials(formValues['contactFirstName'], formValues['contactLastName'])} className="mr-2" size="xlarge" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />
                                    </div>
                                    <div>
                                        <h4>
                                            {formValues['contactFirstName']} {formValues['contactLastName']}
                                        </h4>
                                    </div>
                                </div>
                            }
                        >
                            <div className="grid flex align-items-center justify-content-center">
                                <div className="col-12 md:col-6">
                                    {CHAT_FEATURES && <Button
                                        label="Send Message"
                                        className="field-checkbox"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    ></Button>}

                                    <Button
                                        label="Show Phone Number"
                                        className="field-checkbox"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    ></Button>
                                </div>
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    };

    const RenderSelectImages = () => {
        return (
            <Controller
                name="photos"
                control={control}
                render={({ field, fieldState }) => (
                    <div className="card">
                        <FileUpload
                            className="custom-file-upload"
                            files
                            id={field.name}
                            customUpload
                            ma
                            onSelect={({ files }) => field.onChange(Array.from(files))}
                            onRemove={(e) => {
                                const updatedFiles = field.value.filter((file) => file.name !== e.file.name);
                                field.onChange(updatedFiles);
                            }}
                            emptyTemplate={emptyTemplate}
                            uploadOptions={{ style: { display: 'none' } }}
                            cancelOptions={{ style: { display: 'none' } }}
                            multiple={true}
                            accept="image/*"
                        />
                    </div>
                )}
            />
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label="Next"
                    icon="pi pi-arrow-circle-right"
                    className="p-button-help"
                    onClick={async (e) => {
                        const isValid = await trigger();
                        isValid && setActiveIndex(activeIndex + 1);
                        console.log('activeIndex', activeIndex);
                    }}
                />
            </React.Fragment>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Previous" icon="pi pi-arrow-circle-left" className="p-button-success mr-2 mb-2" onClick={(e) => setActiveIndex(activeIndex - 1)} />
            </React.Fragment>
        );
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast}></Toast>
                    <Steps model={stepsItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
                </div>
                <form className="p-fluid form" onSubmit={handleSubmit(onSubmitForm)}>
                    {activeIndex === 0 && (
                        <div className="card">
                            <h5>Select Category</h5>
                            {RenderSelectCategory()}
                            <Toolbar className="mb-4" right={rightToolbarTemplate} />
                        </div>
                    )}

                    {activeIndex === 1 && (
                        <div className="card">
                            <h5>Add Description</h5>
                            <div className="card">{RenderDescription()}</div>
                            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                        </div>
                    )}

                    {activeIndex === 2 && (
                        <div className="card">
                            <h5>Add Details</h5>
                            <div className="col-12">
                                <div className="card">
                                    <div className="p-fluid  grid">{RenderCategoryTemplate()}</div>
                                </div>
                                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                            </div>
                        </div>
                    )}

                    {activeIndex === 3 && (
                        <div className="card">
                            <h5>Add Images</h5>
                            {RenderSelectImages()}
                            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                        </div>
                    )}

                    {activeIndex === 4 && (
                        <>
                            <div className="card">
                                <h5>Contact Details</h5>
                                {ContactDetails()}
                                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
                            </div>
                        </>
                    )}

                    {activeIndex === 5 && (
                        <>
                            <h3>Preview</h3>
                            {previewListing(galleriaImages)}
                            
                            <Toolbar className="mb-4" left={leftToolbarTemplate} />

                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Controller
                                        name="accept"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <span className="p-float-label">
                                                    <Checkbox id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.checked)} checked={field.value} />
                                                </span>
                                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.accept })}>
                                                    I Agree To oMarket's{' '}
                                                    <a href="https://your-website.com/terms" target="_blank" rel="noopener noreferrer">
                                                        Terms And Conditions
                                                    </a>
                                                </label>
                                                {getFormErrorMessage(field.name)}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="field-checkbox">
                                    <Controller
                                        name="accept1"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <span className="p-float-label">
                                                    <Checkbox id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.checked)} checked={field.value} />
                                                </span>
                                                <label htmlFor={field.name} className={classNames({ 'p-error': errors.accept1 })}>
                                                    I Agree To oMarket's{' '}
                                                    <a href="https://your-website.com/terms" target="_blank" rel="noopener noreferrer">
                                                        Privacy Policy
                                                    </a>
                                                </label>
                                                {getFormErrorMessage(field.name)}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </form>
               
                {activeIndex === 5 && <Button type="submit" label="Submit Listing" icon="pi pi-check" loading={createdAdLoading} onClick={handleSubmit(onSubmitForm)} />}
                {/*adsCreateError === undefined && ads !== undefined && toast.current.show({ severity: 'success', summary: 'Ads Posted Succesfully', detail: 'event.item.label' })*/}
            
            <ConfirmDialog visible={createdAdLoading} message={<ProgressSpinner />} draggable={false} closable={false} footer={<></>}/>
            </div>
             
        </div>
    );
};

export default ListNewAds;
