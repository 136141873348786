import { Field, Fields } from '../types/Fields/Field';

import api from '../api';

export default class FieldService {
    getByCategory = async (categoryId: string): Promise<Fields[]> => {
        try {
            const response = await api.get<Fields[]>(`/fields/by-category/${categoryId}`);
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(`Error Fetching Fields For Category ${categoryId}`);
        }
    };

    getFieldBySubCategory = async (subCategoryId: string): Promise<Field[]> => {
        try {
            const response = await api.get<Field[]>(`/fields/by-sub-category/${subCategoryId}`);
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw new Error('Network Error');
            }
            throw new Error(`Error Fetching Field For SubCategory ${subCategoryId}`);
        }
    };

    createField = async (field: any) => {
        const response = await api.post('/fields/create', field);
        return response.data;
    };
}
