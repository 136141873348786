import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/utils/ReduxUtil';

import { Button } from 'primereact/button';
import CategoryService from '../../service/CategoryService';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { Prefix } from '../../types/Constants/LocalStorage';
import ProductService from '../../service/ProductService';
import { Rating } from 'primereact/rating';
import SearchCard from './search-card/SearchCard';
import { Skeleton } from 'primereact/skeleton';
import { cities } from '../../types/Constants/Cities';
import { onCheckToken } from '../../store/features/auth/AuthAction';
import { onFindAdsByCategory } from '../../store/features/ads/AdsAction';
import { onGetAll } from '../../store/features/category/CategoryAction';
import { useLocation } from 'react-router-dom';

const AdsFeed = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { categories, categoryError, categoryLoading } = useAppSelector((state) => state.category);
    const { adsByCategory, adsByCategoryError, adsByCategoryLoading } = useAppSelector((state) => state.ads);
    const [citiesValue, setCitiesValue] = useState(null);
    const [categoriesValue, setCategoriesValue] = useState(null);
    const [loading1, setLoading1] = useState(false);
    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [rows, setRows] = useState(21);
    const [first, setFirst] = useState(0);
    const [categoryId, setCategoryId] = useState(null);
    const [categoryName, setCategoryName] = useState(null);

    useEffect(() => {
        dispatch(onCheckToken());
        dispatch(onGetAll({}));
    }, [dispatch]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setCategoryName(queryParams.get('category'));
        if (categoryName) {
            const service = new CategoryService();
            service.findByName(categoryName).then((data) => {
                setCategoryId(data._id)
            });
        }
    }, [location, categoryName]);

   
    useEffect(() => {
        if (categoryName) {
            const category = categories.find((category) => category._id === categoryId);
            setCategoriesValue(category);
        }
    }, [categoryId, categoryName, setCategoriesValue]);

    useEffect(() => {
        if (categoryId) {
            dispatch(onFindAdsByCategory({ categoryId: categoryId, skip: first, limit: rows }));
        }
    }, [categoryId, categoryName, first, rows, dispatch]);


    const sortOptions = [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
    ];

    const handlePageChange = (event) => {
        console.log(event);
        setFirst(event.first);
        // setRows(event.rows);
    };

    const onLoadingClick = () => {
        setLoading1(true);
        console.log(citiesValue)
        console.log(categoriesValue)

        setTimeout(() => {
            setLoading1(false);
        }, 2000);
    };

    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    };

    const handleAdsClick = (category, selectedAd) => {
        const queryParams = new URLSearchParams({
            "ad-id": selectedAd._id,
            "sub-category-id": selectedAd.subCategoryId
        });
        const newTabUrl = `/ad-details?${queryParams.toString()}`;
        window.open(newTabUrl, '_blank');
    };

    const dataviewHeader = (
        <div className="grid grid-nogutter">
            <div className="col-6" style={{ textAlign: 'left' }}>
                <Dropdown value={sortKey} options={sortOptions} optionLabel="label" placeholder="Sort By Price" onChange={onSortChange} />
            </div>
            <div className="col-6" style={{ textAlign: 'right' }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        </div>
    );

    const dataviewListItem = (data) => {
        return (
            <>
                <div className="col-12 list-demo">
                    <div className="product-list-item">
                        <img src={`assets/demo/images/product/${data.image}`} alt={data.name} />
                        <div className="product-list-detail">
                            <div className="product-name">{data.name}</div>
                            <div className="product-description">{data.description}</div>

                            {/*<Rating value={data.rating} readOnly cancel={false}></Rating>
                        <i className="pi pi-tag product-category-icon"></i>
                        <span className="product-category">{data.category}</span>*/}
                        </div>
                        <div className="product-list-action">
                            <span className="product-price text-green-500 font-medium text-xl">{data.price} TND</span>
                            {/*<Button icon="pi pi-shopping-cart" label="Add to Cart" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button>
                        <Button icon="pi pi-shopping-cart" label="Add to Cart" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button>
                        <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span>*/}
                        </div>
                    </div>
                </div>
                <Divider />
            </>
        );
    };

    const dataviewGridItem = (data) => {
        return (
            <div className="col-12 md:col-4">
                <div className="product-grid-item card">
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            // window.open(`/#/${categoryName.toLowerCase()}/${data._id}`, '_blank')
                            handleAdsClick(categoryName, data);
                        }}
                    >
                        {/*<div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category">{data.category}</span>
                        </div>
                            <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span>
                    </div>*/}
                        <div className="product-grid-item-content">
                            {data.images.length > 0 ? <img src={data.images[0]} alt={data.title} /> : <img src={'assets/demo/images/banner-primeblocks-dark.png'} alt={data.title} />}
                            <div className="product-description">{data.title.slice(0, 40)} ...</div>
                            
                        </div>
                        <div className="product-grid-item-bottom">
                            <span className="product-price text-green-500 font-medium text-xl">{data.fields.price || '--'} TND</span>
                            {/*<Button icon="pi pi-shopping-cart" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }

        if (layout === 'list') {
            return dataviewListItem(data);
        } else if (layout === 'grid') {
            return dataviewGridItem(data);
        }
    };

    return (
        <>
           
           <SearchCard categories={categories} cities={cities} />
            {adsByCategoryLoading === false && adsByCategoryError === undefined && adsByCategory.ads.length > 0 ? (
                <div className="grid list-demo">
                    <div className="col-12">
                        <div className="card">
                            <h5>Latest ADS in {categoryName}</h5>
                            <DataView
                                value={adsByCategory.ads}
                                layout={layout}
                                sortOrder={sortOrder}
                                sortField={sortField}
                                itemTemplate={itemTemplate}
                                header={dataviewHeader}
                            />
                            <Paginator first={first} rows={rows} totalRecords={adsByCategory.totalRecords} onPageChange={handlePageChange}></Paginator>
                        </div>
                    </div>
                </div>
            ) : (
                <Skeleton width="100%" height="180px"></Skeleton>
            )}
        </>
    );
};

export default AdsFeed;
