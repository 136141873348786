import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { onFindAdsWithOptions, onFindLatestByCategory } from '../../store/features/ads/AdsAction';
import { useAppDispatch, useAppSelector } from '../../hooks/utils/ReduxUtil';

import AdsCard from './ads-card/AdsCard';
import { Carousel } from 'primereact/carousel';
import { Category } from '../../types/Categories/Category';
import { Prefix } from '../../types/Constants/LocalStorage';
import ProductService from '../../service/ProductService';
import SearchCard from './search-card/SearchCard';
import { Skeleton } from 'primereact/skeleton';
import { cities } from '../../types/Constants/Cities';
import { latestAdsOptions } from '../../utilities/Utils';
import { onCheckToken } from '../../store/features/auth/AuthAction';
import { onGetAll } from '../../store/features/category/CategoryAction';

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { categories, categoryError, categoryLoading } = useAppSelector((state) => state.category);
    const { adsWithOptions, adsWithOptionsError, adsWithOptionsLoading } = useAppSelector((state) => state.ads);
    const { latestAds, latestAdsError, latestAdsLoading } = useAppSelector((state) => state.ads);
    const [products, setProducts] = useState([]);
    const [citiesValue, setCitiesValue] = useState<any>(null);
    const [categoriesValue, setCategoriesValue] = useState<any>(null);
    const [loading1, setLoading1] = useState(false);
    const [visible, setVisible] = useState(false);
    const { isLoggedIn, user } = useAppSelector((state) => state.auth);

    const carouselResponsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(() => {
        dispatch(onCheckToken());
        dispatch(onGetAll({}));
    }, []);

    useEffect(() => {
        const productService = new ProductService();
        productService.getProductsSmall().then((products) => setProducts(products));
    }, []);

    useEffect(() => {
        // dispatch(onFindWithOptions(latestAdsOptions('64302a7e054318fd39f3d178')));
    }, []);

    useEffect(() => {
        dispatch(onFindLatestByCategory({}));
    }, []);

    const onLoadingClick = () => {
        setLoading1(true);

        setTimeout(() => {
            setLoading1(false);
        }, 2000);
    };

    const handleCategoryClick = (category: Category) => {
        const queryParams = new URLSearchParams({
            category: category.name.toLowerCase()
        });
        const url = `/ads-feed?${queryParams.toString()}`;
        window.open(url, '_blank');
    };

    const carouselItemTemplate = (category: any) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div
                        onClick={() => handleCategoryClick(category)}
                        style={{ cursor: 'pointer' }}
                        //to={{
                        //  pathname: `/${category.name.toLowerCase()}/ads-feed`

                        // search: `?categoryId=${category._id}&categoryName=${encodeURIComponent(category.name)}`
                        //}}
                        //state={{ categoryId: category._id, categoryName: category.name }}
                        // target="_blank"
                    >
                        <div>
                            <div className="mb-3">
                                <img src={'assets/demo/images/banner-primeblocks-dark.png'} alt={category.name} className="product-image" />
                            </div>
                            <div>
                                <a>
                                    <h4 className="p-mb-1">{category.name}</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <SearchCard categories={categories} cities={cities} />

            {/*SearchCard(categories, categoriesValue, setCategoriesValue, cities, citiesValue, setCitiesValue, loading1, onLoadingClick)*/}

            <div className="grid list-demo">
                <div className="col-12">
                    <div className="card">
                        <div className="grid p-fluid media-demo">
                            <div className="col-12">
                                <h5>Top Categories</h5>
                                {categoryLoading === false && categoryError === undefined ? (
                                    <Carousel autoplayInterval={3000} value={categories as any} numVisible={4} numScroll={3} responsiveOptions={carouselResponsiveOptions} itemTemplate={carouselItemTemplate} />
                                ) : (
                                    <Skeleton width="100%" height="180px"></Skeleton>
                                )}
                            </div>
                        </div>
                    </div>

                    {categories.map((category) => {
                        return latestAds
                            .filter((latest) => latest._id === category._id)
                            .map((latest) => {
                                return (
                                    <div className="card">
                                        <h5>
                                            Latest in{' '}
                                            {
                                                <a href="https://your-website.com/terms" target="_blank" rel="noopener noreferrer">
                                                    {category.name}
                                                </a>
                                            }
                                        </h5>
                                        {latestAdsLoading === false && latestAdsError === undefined && latestAds.length > 0 ? (
                                            <Carousel value={latest.ads.filter((ad) => ad.images.length > 0 && ad.fields.price)} numVisible={4} numScroll={3} responsiveOptions={carouselResponsiveOptions} itemTemplate={AdsCard} />
                                        ) : (
                                            <Skeleton width="100%" height="180px"></Skeleton>
                                        )}
                                    </div>
                                );
                            });
                    })}
                </div>
            </div>
        </>
    );
};

export default Home;
