import adsSlice from './features/ads/AdsSlice';
import authSlice from './features/auth/AuthSlice';
import categorySlice from './features/category/CategorySlice';
import { configureStore } from '@reduxjs/toolkit';
import fieldsSlice from './features/field/FieldsSlice';
import fileUploadSlice from './features/file-upload/FileUploadSlice';

export const Store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: [
                    'ads/find-latest-by-category/rejected',
                    'auth/register/rejected',
                    'auth/login/rejected',
                    'category/all/rejected',
                    'fields/bySubCategory/rejected',
                    'ads/create/rejected',
                    'fileUpload/ads-images/rejected',
                    'ads/find-with-options/rejected',
                    'auth/resend-verification-email/rejected',
                    'auth/forgotPassword/rejected'
                ],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['meta.arg'],
                // Ignore these paths in the state
                ignoredPaths: []
            }
        }),
    reducer: {
        auth: authSlice,
        category: categorySlice,
        fields: fieldsSlice,
        ads: adsSlice,
        fileUpload: fileUploadSlice
    }
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
